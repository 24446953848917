import { RootState } from '../store'
import { getFreeTrial, getShouldBuySubscription } from '../../helpers/freeTrial'
import { createSelector } from 'reselect'

export const selectError = (state: RootState) => state.auth.error

export const selectLastLoginAt = (state: RootState) => state.auth.userData.lastLoginAt

export const selectUserId = (state: RootState) => state.auth.userData.id

export const selectUserEmail = (state: RootState) => state.auth.userData.email

export const selectRemainingRequests = (state: RootState) => state.auth.userData.remainingRequests

export const selectFeatureFlags = (state: RootState) => state.auth.userData.features

export const selectSiteFeatures = (state: RootState) => state.auth.siteFeatures

export const selectTeamName = (state: RootState) => {
  const siteFeatures = state.auth.siteFeatures
  if (siteFeatures === undefined || siteFeatures.platformConfig?.teamName === undefined) {
    return 'AIMS Team'
  }
  return siteFeatures.platformConfig?.teamName
}

export const selectLoginLogo = (state: RootState) => {
  const siteFeatures = state.auth.siteFeatures
  return siteFeatures?.platformConfig?.branding?.login?.logo
}

export const selectStripeCustomerId = (state: RootState) => state.auth.userData.stripeCustomerId

export const selectSubscriptionStatus = (state: RootState) => state.auth.userData.subscriptionStatus

export const selectStripeProductId = (state: RootState) => state.auth.userData.stripeProductId

export const selectFreeTrial = createSelector(
  [
    (state: RootState) => state.auth.userData.trialExpiresAt,
    (state: RootState) => state.auth.userData.subscriptionStatus,
  ],
  (trialExpiresAt, subscriptionStatus) => {
    return getFreeTrial(trialExpiresAt, subscriptionStatus)
  },
)

export const selectShouldBuySubscription = (state: RootState) => {
  const status = selectSubscriptionStatus(state)
  const freeTrial = selectFreeTrial(state)
  return getShouldBuySubscription(status, freeTrial.trialExpiresAt)
}

export const selectStreamingHash = (state: RootState) => state.auth.userData.streamingHash
