import { useDispatch, useSelector } from 'react-redux'
import { ConfirmOptions, useConfirm } from 'material-ui-confirm'
import type { TypedUseSelectorHook } from 'react-redux'
import type { RootState, AppDispatch } from './store'

const confirmDefaults: ConfirmOptions = {
  cancellationButtonProps: {
    size: 'small',
    sx: (theme) => ({
      width: 'fit-content',
      borderRadius: '8px',
      color: theme.palette.mode === 'dark' ? 'black' : theme.palette.secondary.dark,
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[200],
        color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : 'initial',
      },
    }),
  },
  confirmationButtonProps: {
    size: 'small',
    sx: (theme) => ({
      width: 'fit-content',
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.main,
      borderRadius: '8px',
      '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light,
      },
    }),
  },
  dialogProps: {
    PaperProps: {
      sx: {
        maxWidth: '500px',
        pt: 4,
        px: 2,
      },
    },
  },
  contentProps: {
    sx: {
      textAlign: 'center',
    },
  },
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppConfirm: () => (options?: ConfirmOptions) => Promise<void> = () => {
  const confirm = useConfirm()
  return async (options?: ConfirmOptions) => await confirm({ ...confirmDefaults, ...options })
}
