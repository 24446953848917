import { createAsyncThunk } from '@reduxjs/toolkit'
import { CustomError, parseErrorFromThunk } from '../../helpers/errors'
import { axiosInstance } from '../../config/axios'
import { DefaultCategory } from '../../types/categories'

export const getCategories = createAsyncThunk<
  DefaultCategory[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
  }
>('category/list', async (_, thunkApi) => {
  try {
    const { data } = await axiosInstance.get('/api/category/list')
    return data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})
