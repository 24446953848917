import { LoadingStatus } from '@/consts/common'
import { DiscoveryInputs, DiscoverySearch, Filter, SearchParams, initialSearchParams } from '@/types/discovery'
import { DetailedTrack } from '@/types/discovery/track'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { AutocompleteResponse } from '@aims-api/aims-node/dist/helpers/types/search'
import {
  autocomplete,
  fetchFilterFieldValues,
  promptSuggestions,
  saveSearch,
  search,
  searchByFile,
  searchByFileHash,
  searchByFileLink,
  searchById,
  searchByLink,
  searchByMetadata,
  searchByText,
  searchByTextHash,
} from './thunks'

export interface SegmentRange {
  from: number | null
  to: number | null
}
interface SegmentContent {
  duration: number | null
  changed: boolean
  track: DetailedTrack | null
  embeddedPlayerReady: boolean
}
interface DiscoveryStore {
  recentSearches: DiscoverySearch[]
  results: DetailedTrack[]
  resultsLoadingStatus: (typeof LoadingStatus)[keyof typeof LoadingStatus]
  chipLoadingStatus: (typeof LoadingStatus)[keyof typeof LoadingStatus]
  page?: number
  hasMoreResults?: boolean
  segmentRange: SegmentRange
  appliedSearchParams: {
    queryParams: SearchParams
    segmentRange: SegmentRange
  } | null
  segmentContent: SegmentContent
  thumbnails: { [id: string]: string }
  filterFields: { [field: string]: string[] }
  filtersForAPI?: Filter
  filtersFormShouldReset: boolean
  autocompleteResults: { [query: string]: AutocompleteResponse }
  promptSuggestionsResults: { [query: string]: AutocompleteResponse }
  activeSearch: DiscoverySearch | null
  searchParams: SearchParams
  filtersOpen: boolean
  trackInfo?: DetailedTrack
  trackInfoOpen: boolean
  hasUserInteractedWithSegment?: true
}

export const DEFAULT_PAGE_SIZE = 20

const initialSegmentRange = {
  from: null,
  to: null,
}

export const initialState: DiscoveryStore = {
  recentSearches: [],
  thumbnails: {},
  appliedSearchParams: null,
  segmentRange: initialSegmentRange,
  segmentContent: {
    duration: null,
    track: null,
    changed: false,
    embeddedPlayerReady: false,
  },
  results: [],
  resultsLoadingStatus: LoadingStatus.IDLE,
  chipLoadingStatus: LoadingStatus.IDLE,
  filterFields: {},
  filtersFormShouldReset: false,
  activeSearch: null,
  autocompleteResults: {},
  promptSuggestionsResults: {},
  searchParams: initialSearchParams,
  filtersOpen: false,
  trackInfoOpen: false,
}

export const discoverySlice = createSlice({
  name: 'discovery',
  initialState,
  reducers: {
    setResultsLoadingStatus(state, action: PayloadAction<(typeof LoadingStatus)[keyof typeof LoadingStatus]>) {
      state.resultsLoadingStatus = action.payload
    },
    setChipLoadingStatus(state, action: PayloadAction<(typeof LoadingStatus)[keyof typeof LoadingStatus]>) {
      state.chipLoadingStatus = action.payload
    },
    setSearchParams(state, action: PayloadAction<SearchParams | null>) {
      state.searchParams = action.payload ?? initialSearchParams
    },
    setActiveSearch(state, action: PayloadAction<DiscoverySearch | null>) {
      state.activeSearch = action.payload
    },
    resetPage(state) {
      state.page = undefined
    },
    clearResults(state) {
      state.results = initialState.results
    },
    resetSearch(state) {
      state.hasMoreResults = undefined
      state.results = initialState.results
      state.resultsLoadingStatus = LoadingStatus.IDLE
      state.activeSearch = null
      state.filtersForAPI = undefined
    },
    clearSegment(state) {
      state.segmentContent = initialState.segmentContent
    },
    setEmbeddedPlayerReady(state, action: PayloadAction<boolean>) {
      state.segmentContent.embeddedPlayerReady = action.payload
    },
    setSegmentDuration(state, action: PayloadAction<number | null>) {
      state.segmentContent.duration = action.payload
    },
    submitAppliedSearchParams(state) {
      state.appliedSearchParams = { queryParams: state.searchParams, segmentRange: state.segmentRange }
    },
    setAppliedSearchParams(state, action: PayloadAction<{ queryParams: SearchParams } | null>) {
      if (action.payload === null) {
        state.appliedSearchParams = null
        return
      }
      const { queryParams } = action.payload
      const segmentRange = state.segmentRange
      state.appliedSearchParams = { queryParams, segmentRange }
    },
    setSegmentRange(state, action: PayloadAction<{ from: number | null; to: number | null; init?: boolean } | null>) {
      if (action.payload === null) {
        state.segmentRange = initialSegmentRange
        return
      }
      state.segmentRange.from = action.payload.from
      state.segmentRange.to = action.payload.to

      // Announcement: flag when user interacts with segment tool
      if (action.payload.init === true) {
        return
      }
      state.hasUserInteractedWithSegment = true
    },
    setThumbnail(state, action: PayloadAction<{ id: string; thumbnail: string }>) {
      const { id, thumbnail } = action.payload
      state.thumbnails = {
        ...state.thumbnails,
        [id]: thumbnail,
      }
    },
    setFiltersForAPI(state, action: PayloadAction<Filter | undefined>) {
      state.filtersForAPI = action.payload
    },
    setFiltersFormShouldReset(state, action: PayloadAction<boolean>) {
      state.filtersFormShouldReset = action.payload
    },
    toggleFilterOpen(state) {
      if (state.trackInfoOpen) {
        state.trackInfoOpen = false
        state.trackInfo = undefined
        if (state.filtersOpen) {
          return
        }
      }
      state.filtersOpen = !state.filtersOpen
    },
    setTrackInfo(state, action: PayloadAction<DetailedTrack | undefined>) {
      state.trackInfo = action.payload
      if (action.payload !== undefined) {
        state.trackInfoOpen = true
      }
    },
    closeTrackInfo(state) {
      state.trackInfoOpen = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveSearch.pending, (state, action) => {
      if (action.meta.arg.page === undefined || action.meta.arg.page === 1) {
        // case: loading first page
        if (
          (state.activeSearch !== null && state.resultsLoadingStatus === LoadingStatus.IDLE) ||
          (state.activeSearch !== null && action.meta.arg.search.title !== state.activeSearch.title) ||
          state.activeSearch === null
        ) {
          state.segmentRange = initialSegmentRange
          state.filtersForAPI = undefined
          state.filtersFormShouldReset = true
          state.searchParams = initialSearchParams
        }
        state.resultsLoadingStatus = LoadingStatus.LOADING
        state.results = initialState.results
        state.activeSearch = action.meta.arg.search
      }
    })
    builder.addCase(saveSearch.fulfilled, (state, action) => {
      if (action.meta.arg.page !== undefined) {
        // case: loading page > 1, no need to save
        return
      }
      const { search, userId } = action.payload

      // remove search if already exists in recentSearches to keep the list unique
      state.recentSearches = state.recentSearches.filter((recentSearch) => {
        if (recentSearch.title === search.title) {
          return false
        }
        if (search.link !== undefined && recentSearch.link === search.link) {
          return false
        }
        if (
          search?.internalTrack?.id_client !== undefined &&
          recentSearch?.internalTrack?.id_client === search.internalTrack.id_client
        ) {
          return false
        }
        if (search.hash !== undefined && recentSearch.hash === search.hash) {
          return false
        }
        return true
      })

      // uploadInfo is useless to save to localStorage
      // isSegmentToolAvailable is set in FileUpload component
      const { uploadInfo, isSegmentToolAvailable, ...rest } = search

      // add search to the top of the list
      void state.recentSearches.unshift(rest)
      // saves recent searches into browser
      localStorage.setItem(`discovery_${userId}`, JSON.stringify(state.recentSearches))
    })
    builder.addCase(searchByText.fulfilled, (state, action) => {
      state.resultsLoadingStatus = LoadingStatus.LOADED
      state.page = 1
      state.results = action.payload.data.tracks
      state.hasMoreResults = action.payload.data.tracks.length === DEFAULT_PAGE_SIZE

      if (action.payload.data.lyrics_search !== undefined && state.activeSearch !== null) {
        state.activeSearch.lyricsSearch = action.payload.data.lyrics_search
      }

      if (state.recentSearches.length > 0 && state.activeSearch !== null) {
        // hash is available
        state.activeSearch.hash = action.payload.data.hash
        state.recentSearches[0].hash = action.payload.data.hash
        // update localStorage
        localStorage.setItem(`discovery_${action.payload.userId}`, JSON.stringify(state.recentSearches))
      }
    })
    builder.addCase(searchByText.rejected, (state) => {
      state.resultsLoadingStatus = LoadingStatus.IDLE
      state.activeSearch = null
    })
    builder.addCase(searchByTextHash.fulfilled, (state, action) => {
      const { page = 1, hash } = action.meta.arg
      state.resultsLoadingStatus = LoadingStatus.LOADED
      if (state.activeSearch !== null && hash !== state.activeSearch.hash && page > 1) {
        return
      }
      state.page = page
      state.results = page === 1 ? action.payload.tracks : state.results.concat(action.payload.tracks)
      state.hasMoreResults = action.payload.tracks.length === DEFAULT_PAGE_SIZE
      if (page === 1) {
        state.resultsLoadingStatus = LoadingStatus.LOADED
        if (action.payload.lyrics_search !== undefined && state.activeSearch !== null) {
          state.activeSearch.lyricsSearch = action.payload.lyrics_search
        }
      }
    })
    builder.addCase(searchByTextHash.rejected, (state) => {
      state.resultsLoadingStatus = LoadingStatus.IDLE
      state.activeSearch = null
    })
    builder.addCase(searchByFile.fulfilled, (state, action) => {
      state.resultsLoadingStatus = LoadingStatus.LOADED
      state.page = 1
      state.results = action.payload.data.tracks
      state.hasMoreResults = action.payload.data.tracks.length === DEFAULT_PAGE_SIZE
      if (state.recentSearches.length > 0 && state.activeSearch !== null) {
        // hash is available
        state.activeSearch.hash = action.payload.data.hash
        state.recentSearches[0].hash = action.payload.data.hash
        // update localStorage
        localStorage.setItem(`discovery_${action.payload.userId}`, JSON.stringify(state.recentSearches))
      }
    })
    builder.addCase(searchByFile.rejected, (state) => {
      state.resultsLoadingStatus = LoadingStatus.IDLE
      state.activeSearch = null
    })
    builder.addCase(searchByFileHash.fulfilled, (state, action) => {
      const { page = 1, hash } = action.meta.arg
      if (state.activeSearch !== null && hash !== state.activeSearch.hash && page > 1) {
        return
      }
      state.resultsLoadingStatus = LoadingStatus.LOADED
      state.page = page
      state.results = page === 1 ? action.payload.tracks : state.results.concat(action.payload.tracks)
      state.hasMoreResults = action.payload.tracks.length === DEFAULT_PAGE_SIZE
    })
    builder.addCase(searchByFileHash.rejected, (state) => {
      state.resultsLoadingStatus = LoadingStatus.IDLE
      state.activeSearch = null
    })
    builder.addCase(searchByLink.fulfilled, (state, action) => {
      const { page = 1, link } = action.meta.arg
      if (state.activeSearch !== null && link !== state.activeSearch.link && page > 1) {
        return
      }
      state.resultsLoadingStatus = LoadingStatus.LOADED
      state.page = page
      state.results = page === 1 ? action.payload.tracks : state.results.concat(action.payload.tracks)
      state.hasMoreResults = action.payload.tracks.length === DEFAULT_PAGE_SIZE
    })
    builder.addCase(searchByLink.rejected, (state) => {
      state.resultsLoadingStatus = LoadingStatus.IDLE
      state.activeSearch = null
    })
    builder.addCase(searchByFileLink.fulfilled, (state, action) => {
      const { page = 1, link } = action.meta.arg
      if (state.activeSearch !== null && link !== state.activeSearch.link && page > 1) {
        return
      }
      state.resultsLoadingStatus = LoadingStatus.LOADED
      state.page = page
      state.results = page === 1 ? action.payload.tracks : state.results.concat(action.payload.tracks)
      state.hasMoreResults = action.payload.tracks.length === DEFAULT_PAGE_SIZE
    })
    builder.addCase(searchByFileLink.rejected, (state) => {
      state.resultsLoadingStatus = LoadingStatus.IDLE
      state.activeSearch = null
    })
    builder.addCase(searchById.fulfilled, (state, action) => {
      const { page = 1, trackId } = action.meta.arg
      if (state.activeSearch !== null && trackId !== state.activeSearch.internalTrack?.id_client && page > 1) {
        return
      }
      state.resultsLoadingStatus = LoadingStatus.LOADED
      state.page = page
      state.results = page === 1 ? action.payload.tracks : state.results.concat(action.payload.tracks)
      state.hasMoreResults = action.payload.tracks.length === DEFAULT_PAGE_SIZE
    })
    builder.addCase(searchById.rejected, (state) => {
      state.resultsLoadingStatus = LoadingStatus.IDLE
      state.activeSearch = null
    })
    builder.addCase(searchByMetadata.fulfilled, (state, { payload, meta }) => {
      const { page = 1, text } = meta.arg
      state.resultsLoadingStatus = LoadingStatus.LOADED
      if (
        state.activeSearch !== null &&
        text !== state.activeSearch.title &&
        page > 1 &&
        state.activeSearch.input !== DiscoveryInputs.FILTERS_ONLY
      ) {
        return
      }
      state.page = page
      state.results = page === 1 ? payload.tracks : state.results.concat(payload.tracks)
      state.hasMoreResults = payload.pagination.page_count > page
    })
    builder.addCase(searchByMetadata.rejected, (state) => {
      state.resultsLoadingStatus = LoadingStatus.IDLE
      state.activeSearch = null
    })
    builder.addCase(search.fulfilled, (state, { payload, meta }) => {
      const { page = 1, query } = meta.arg
      if (state.activeSearch !== null && query !== state.activeSearch.title && page > 1) {
        return
      }
      state.resultsLoadingStatus = LoadingStatus.LOADED
      state.page = page
      // TODO: fix type
      const tracks = payload.tracks as DetailedTrack[]
      state.results = page === 1 ? tracks : state.results.concat(tracks)
      state.hasMoreResults = payload.totals.tracks !== undefined && payload.totals.tracks?.value > DEFAULT_PAGE_SIZE
      if (payload.did_you_mean !== undefined && state.activeSearch !== null && page === 1) {
        state.activeSearch.unifiedSearchType = payload.type
        state.activeSearch.didYouMean = payload.did_you_mean
        state.activeSearch.lyricsSearch = payload.lyrics_search
      }
    })
    builder.addCase(search.rejected, (state) => {
      state.resultsLoadingStatus = LoadingStatus.IDLE
    })
    builder.addCase(fetchFilterFieldValues.fulfilled, (state, action) => {
      const field = action.meta.arg.field
      const values = action.payload.values.map((v) => v.value)
      state.filterFields[field] = values
    })
    builder.addCase(autocomplete.fulfilled, (state, action) => {
      const { query } = action.meta.arg
      state.autocompleteResults[query] = action.payload
    })
    builder.addCase(promptSuggestions.fulfilled, (state, action) => {
      const { query } = action.meta.arg
      state.promptSuggestionsResults[query] = action.payload
    })
  },
})

export const {
  setResultsLoadingStatus,
  setChipLoadingStatus,
  setActiveSearch,
  resetSearch,
  clearResults,
  clearSegment,
  setEmbeddedPlayerReady,
  setSegmentDuration,
  setSegmentRange,
  setAppliedSearchParams,
  submitAppliedSearchParams,
  setThumbnail,
  setFiltersForAPI,
  setFiltersFormShouldReset,
  setSearchParams,
  toggleFilterOpen,
  setTrackInfo,
  closeTrackInfo,
  resetPage,
} = discoverySlice.actions

export default discoverySlice.reducer
