import { z } from 'zod'

export const getDataFromLocalStorage = <T extends z.ZodTypeAny>(itemName: string, schema: T): z.infer<T> => {
  try {
    const savedData = localStorage.getItem(itemName)
    if (savedData === null) {
      return
    }
    return itemName === 'presetId' ? schema.parse(savedData) : schema.parse(JSON.parse(savedData))
  } catch (e) {}
}
