export const compareArrOfObjByKeyAsc = <Key extends string, A>(
  obj1: A & { [K in Key]: string },
  obj2: A & { [K in Key]: string },
  key: Key,
): -1 | 1 | 0 => {
  if (obj1[key] < obj2[key]) {
    return -1
  }
  if (obj1[key] > obj2[key]) {
    return 1
  }

  return 0
}

export const isArrEqual = (arr1: unknown, arr2: unknown) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

// Announcement: used to fill z.enum([...]) see https://github.com/colinhacks/zod/discussions/2125#discussioncomment-7452235
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValues<T extends Record<string, any>>(obj: T) {
  return Object.values(obj) as [(typeof obj)[keyof T]]
}

type AnyFunction = (...args: unknown[]) => unknown

export function throttle<F extends AnyFunction>(func: F, limit: number): (...args: Parameters<F>) => void {
  let inThrottle: boolean
  let lastFunc: NodeJS.Timeout
  let lastRan: number

  return function (this: ThisParameterType<F>, ...args: Parameters<F>): void {
    if (!inThrottle) {
      func.apply(this, args)
      lastRan = Date.now()
      inThrottle = true
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(
        () => {
          if (Date.now() - lastRan >= limit) {
            func.apply(this, args)
            lastRan = Date.now()
          }
          inThrottle = false
        },
        Math.max(limit - (Date.now() - lastRan), 0),
      )
    }
  }
}

export const abbreviateNumber = (num: number) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + 'M'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(0) + 'k'
  }
  return String(num)
}

export function formatNumberUS(num: number | undefined | null): string {
  if (num === undefined || num === null) {
    return ''
  }
  return num.toLocaleString('en-US')
}
