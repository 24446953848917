import { z } from 'zod'
import { SubscriptionStatusType } from './subscription'

export type KnownFeatureFlags = Partial<{
  tagEditing: boolean
  taxonomyMapping: boolean
  allTagCategories: boolean
  exportFormats: boolean
  stripeTestEnv: boolean
  taxonomyPresets: boolean
  catalogSection: boolean
  discoverySection: boolean
  admin: boolean
}>

type FeatureFlags = KnownFeatureFlags & SubscriptionStatusResponse['features']
export interface ApiUserData {
  email: string
  id: string
  features: FeatureFlags | null
  trialExpiresAt: undefined | string | null
  stripeCustomerId: string | null
  subscriptionStatus: SubscriptionStatusType
  stripeProductId: string | null
  lastLoginAt: string | null
  remainingRequests?: number
  streamingHash: string | null
}

export const schemaApiUserCredentials = z.object({
  id: z.string(),
  email: z.string().email(),
  streamingHash: z.nullable(z.string()),
})

export const subscriptionStatusResponseSchema = z.object({
  trialExpiresAt: z.nullable(z.string().datetime({ offset: true })),
  stripeCustomerId: z.nullable(z.string()),
  stripeProductId: z.nullable(z.string()),
  subscriptionStatus: z.nullable(z.literal('active').or(z.literal('suspended'))),
  features: z.nullable(
    z
      .object({
        allTagCategories: z.optional(z.boolean()),
        tagEditing: z.optional(z.boolean()),
        exportFormats: z.optional(z.boolean()),
        taxonomyMapping: z.optional(z.boolean()),
      })
      .catchall(z.boolean()),
  ),
})

export type SubscriptionStatusResponse = z.infer<typeof subscriptionStatusResponseSchema>
