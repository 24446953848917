import { grey, primaryOpacity } from '@/styles/palettes/general'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { css, styled } from '@mui/material/styles'
import { forwardRef, ReactNode } from 'react'
import CustomLoading from '../CustomLoading'

export type PaletteColor = 'info' | 'success' | 'warning' | 'error' | 'primary' | 'secondary'

interface CustomButtonProps {
  gradient?: boolean
  children: ReactNode
  color?: PaletteColor
  loading?: boolean
}

const Button = forwardRef<HTMLButtonElement, CustomButtonProps & Omit<MuiButtonProps, 'color'>>(function Button(
  { children, color = 'primary', loading = false, ...props },
  ref,
) {
  return (
    <MuiButton {...props} ref={ref} color={color} disabled={loading || props?.disabled}>
      {loading ? <CustomLoading size={16} /> : children}
    </MuiButton>
  )
})

const defaultStyles = `
  border-radius: 20px;
  box-shadow: none;
  padding: 10px 16px;
  font-weight: 700;
`

const smallStyles = `
  width: 168px;
  height: 35px;
  font-size: 16px;
`

const StyledButton = styled(Button)(({ theme, color = 'primary', size }) => {
  let appliedStyles = defaultStyles

  if (size === 'small') {
    appliedStyles += smallStyles
  }

  if (color === 'primary') {
    if (theme.palette.mode === 'light') {
      appliedStyles += `
      background: ${theme.palette.primary.main};
      color: #fff;
      border: none;
     
      &:hover {
        background: ${theme.palette.primary.dark}
      }

      &.Mui-disabled {
        background: ${grey[200]};
        border-color: initial;
        color: ${grey[400]};
        border: none;
      }
      `
    } else {
      appliedStyles += `
        background-color: transparent;
        border: 1px solid ${theme.palette.primary.main};
        color: #fff;

        &:hover {
          background: #4f247c;
        }

        &:active, &:focus {
          background: rgba(150, 16, 255, 0.5);
          border: 1px solid ${theme.palette.primary.dark};
        }

        &.Mui-disabled {
          background: transparent;
          border-color: ${grey[300]};
          color: ${grey[300]};
        }
      `
    }
  }

  if (color === 'secondary') {
    if (theme.palette.mode === 'light') {
      appliedStyles += `
      color: ${grey[600]};
     
      &:hover {
        background: ${primaryOpacity};
      }

      &:active, &:focus {
        background: ${primaryOpacity};
      }

      &.Mui-disabled {
        background-color: #FBF5FF;
        color: ${grey[200]};
      }
      `
    } else {
      appliedStyles += `
        background: linear-gradient(180deg, #211D25 0%, #0D0911 100%);
        color: #FBF5FF;

        &:hover {
          transition-duration: unset;
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid; 
          border-color: rgba(251, 245, 255, 0.3);
        }

        &:active, &:focus {
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid; 
          border-color: rgba(251, 245, 255, 0.3);
        }
        
        &.Mui-disabled {
          background: linear-gradient(180deg, rgba(251, 245, 255, 0.2) 0%, rgba(251, 245, 255, 0.1) 100%);
          color: ${grey[300]};
        }
      `
    }
  }

  return css(appliedStyles)
})

export default StyledButton
