import React, { PropsWithChildren } from 'react'
import ResultTemplate, { State } from '../ResultTemplate'
import AuthLayout from '../AuthLayout'

interface ComponentState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<PropsWithChildren, ComponentState> {
  constructor(props: PropsWithChildren) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <AuthLayout>
          <ResultTemplate state={State.Error} />
        </AuthLayout>
      )
    }

    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
