export const PROD_ENV_HOSTNAME = 'app.aimsapi.com'

export const B_IN_MB = 1048576

export const SUPPORT_MAIL = 'support@aimsapi.com'

export const DEFAULT_ENABLED_FEATURES = {
  admin: true, // Announcement: to see /settings/plans after trial ends
  catalogSection: true,
}

// Announcement: if trial is running, user sees catalog section
export const TRIAL_FEATURES = {
  ...DEFAULT_ENABLED_FEATURES,
  tagEditing: true,
  taxonomyMapping: true,
  exportFormats: true,
  allTagCategories: true,
  taxonomyPresets: true,
  admin: true, // Announcement: to see /settings/plans
}

export const availablePlans = ['Starter', 'Standard'] as const
// live & test
export const AIMS_STANDARD_PLAN_ID = ['prod_NxCrmdJL1GKdTu', 'prod_NxCwY6xXIyh79Y']

export const matchSorterKeys = [
  'tags.genres',
  'tags.moods',
  'tags.usages',
  'tags.vocals',
  'tags.instruments',
  'tags.key',
  'tags.decades',
  'tags.tempo',
  'tags.bpm',
]

export const LoadingStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
} as const

export const UpdatingStatus = {
  IDLE: 'idle',
  UPDATING: 'updating',
  UPDATED: 'updated',
  ERROR: 'error',
} as const

export const DeletingStatus = {
  IDLE: 'idle',
  DELETING: 'deleting',
  DELETED: 'deleted',
  ERROR: 'error',
} as const
