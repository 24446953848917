import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomError } from '../../helpers/errors'
import {
  createBatch,
  deleteBatch,
  deleteTrack,
  getBatchList,
  renameBatch,
  getBatchDetail,
  startTagging,
  removeTag,
  addTag,
} from './thunks'
import { BatchList, Track } from '../../types/batch'
import { sortTracks, updateBatch } from './helpers'
import { LoadingStatus } from '../../consts/common'

interface Progress {
  completedItemsCount: number
  totalItemsCount: number
}

type SingleProgressStatus = Progress &
  (
    | {
        event: 'uploading'
      }
    | {
        event: 'tagging'
        trackIds: string[]
      }
  )

export interface BatchState {
  list: BatchList
  listLoadingStatus: (typeof LoadingStatus)[keyof typeof LoadingStatus]
  detailLoadingStatus: (typeof LoadingStatus)[keyof typeof LoadingStatus]
  trackFilterQuery: string
  progressStatus: null | Record<string, SingleProgressStatus>
  error: CustomError
}

const initialState: BatchState = {
  list: [],
  listLoadingStatus: LoadingStatus.IDLE,
  detailLoadingStatus: LoadingStatus.IDLE,
  trackFilterQuery: '',
  progressStatus: null,
  error: {
    status: null,
    message: '',
  },
}

export const batchSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    trackReceivedFromApi(state, action: PayloadAction<{ batchId: string; trackId: string; track: Track }>) {
      const { batchId, trackId, track: newTrack } = action.payload
      state.list = state.list.map((batch) => {
        if (batch.id === batchId) {
          // eslint-disable-next-line no-param-reassign
          batch.tracks = batch.tracks.map((track) => {
            if (track.id === trackId) {
              return {
                ...track,
                ...newTrack,
                ...(newTrack.duration === null ? { duration: track.duration } : { duration: newTrack.duration }),
                idForPlayer: trackId,
              }
            }
            return track
          })
        }
        return batch
      })
      if (
        state.progressStatus !== null &&
        batchId in state.progressStatus &&
        state.progressStatus[batchId].event === 'uploading'
      ) {
        state.progressStatus[batchId].completedItemsCount++
      }
    },
    trackReceivedFromDropzone(state, action: PayloadAction<{ id: string; track: Track }>) {
      state.list = state.list.map((batch) => {
        if (batch.id === action.payload.id) {
          const index = batch.tracks.findIndex((t) => t.processingError === null)
          batch.tracks.splice(index, 0, action.payload.track)
        }
        return batch
      })
    },
    trackRemove(state, action: PayloadAction<{ batchId: string; trackId: string }>) {
      const { batchId } = action.payload
      if (
        state.progressStatus !== null &&
        batchId in state.progressStatus &&
        state.progressStatus[batchId].event === 'uploading'
      ) {
        state.progressStatus[batchId].totalItemsCount--
      }
      state.list = state.list.map((batch) => {
        if (batch.id === action.payload.batchId) {
          // eslint-disable-next-line no-param-reassign
          batch.tracks = batch.tracks.filter((track) => track.id !== action.payload.trackId)
        }
        return batch
      })
    },
    deleteBatchFromStore(state, action: PayloadAction<string>) {
      state.list = state.list.filter((batch) => batch.id !== action.payload)
    },
    updateTrackTagsInStore(
      state,
      action: PayloadAction<{ batchId: string; trackId: string; categoryName: string; value: string[] }>,
    ) {
      const { batchId, value, categoryName, trackId } = action.payload
      state.list = state.list.map((batch) => {
        if (batch.id === batchId) {
          return {
            ...batch,
            tracks: batch.tracks.map((track) => {
              if (track.id === trackId && track.tags !== null) {
                return {
                  ...track,
                  tags: { ...track.tags, [categoryName]: value },
                }
              }

              return track
            }),
          }
        }
        return batch
      })
    },
    updateTrackFilterQuery(state, action: PayloadAction<string>) {
      state.trackFilterQuery = action.payload
    },
    updateProgressStatus(state, action: PayloadAction<{ batchId: string; status: SingleProgressStatus | null }>) {
      if (action.payload.status === null) {
        if (state.progressStatus === null) {
          return
        }
        const { [action.payload.batchId]: _, ...rest } = state.progressStatus
        state.progressStatus = rest
        return
      }
      state.progressStatus = { ...state.progressStatus, [action.payload.batchId]: action.payload.status }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBatchList.pending, (state) => {
      // [AIMS-2858] resets list if batch was addded previously by getBatchDetail
      if (state.listLoadingStatus === LoadingStatus.IDLE && state.list.length > 0) {
        state.list = []
      }
      state.error = initialState.error
      state.listLoadingStatus = LoadingStatus.LOADING
    })
    builder.addCase(getBatchList.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      state.listLoadingStatus = LoadingStatus.LOADED

      if (payload.length === 0) {
        state.list = []
        return state
      }
      payload.forEach((batchNew) => {
        const sortedTracks = sortTracks(batchNew.tracks)
        const batchWithSortedTracks = {
          ...batchNew,
          tracks: sortedTracks,
        }
        // eslint-disable-next-line no-param-reassign
        state = updateBatch(state, batchWithSortedTracks)
      })
      return state
    })
    builder.addCase(getBatchList.rejected, (state, { payload }) => {
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
      state.listLoadingStatus = LoadingStatus.ERROR
    })
    builder.addCase(createBatch.pending, (state) => {
      state.detailLoadingStatus = LoadingStatus.LOADING
    })
    builder.addCase(createBatch.fulfilled, (state, { payload }) => {
      state.detailLoadingStatus = LoadingStatus.IDLE
      state.list = [payload].concat(state.list)
      state.error = initialState.error
    })
    builder.addCase(createBatch.rejected, (state, { payload }) => {
      state.detailLoadingStatus = LoadingStatus.IDLE
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
    builder.addCase(renameBatch.pending, (state) => {
      state.detailLoadingStatus = LoadingStatus.LOADING
    })
    builder.addCase(renameBatch.fulfilled, (state, { payload }) => {
      state.list = state.list.map((batch) => (batch.id === payload.id ? payload : batch))
      state.error = initialState.error
      state.detailLoadingStatus = LoadingStatus.IDLE
    })
    builder.addCase(renameBatch.rejected, (state, { payload }) => {
      state.detailLoadingStatus = LoadingStatus.IDLE
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
    builder.addCase(deleteBatch.pending, (state) => {
      state.detailLoadingStatus = LoadingStatus.LOADING
    })
    builder.addCase(deleteBatch.fulfilled, (state) => {
      state.error = initialState.error
      state.detailLoadingStatus = LoadingStatus.IDLE
    })
    builder.addCase(deleteBatch.rejected, (state, { payload }) => {
      state.detailLoadingStatus = LoadingStatus.IDLE
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
    builder.addCase(getBatchDetail.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      const sortedTracks = sortTracks(payload.tracks)
      updateBatch(state, { ...payload, tracks: sortedTracks })
    })
    builder.addCase(getBatchDetail.rejected, (state, { payload }) => {
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
    builder.addCase(startTagging.pending, (state, action) => {
      state.error = initialState.error
      const { batchId, totalItemsCount, trackIds } = action.meta.arg
      state.list = state.list.map((batch) => {
        if (batch.id === batchId) {
          // eslint-disable-next-line no-param-reassign
          batch.taggingStartedAt = new Date().toISOString()
        }
        return batch
      })
      state.progressStatus = {
        ...state.progressStatus,
        [batchId]: {
          event: 'tagging',
          trackIds,
          totalItemsCount,
          completedItemsCount: 0,
        },
      }
    })
    builder.addCase(startTagging.rejected, (state, { meta, payload }) => {
      if (state.progressStatus !== null) {
        // removes pending progress
        const { [meta.arg.batchId]: _, ...rest } = state.progressStatus
        state.progressStatus = rest
      }

      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
    builder.addCase(deleteTrack.fulfilled, (state, { payload }) => {
      state.list = state.list.map((batch) => {
        if (batch.id === payload.batchId) {
          // eslint-disable-next-line no-param-reassign
          batch.tracks = batch.tracks.filter((track) => track.id !== payload.track.id)
        }
        return batch
      })
      state.error = initialState.error
    })
    builder.addCase(deleteTrack.rejected, (state, { payload }) => {
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
    builder.addCase(removeTag.pending, (state, action) => {
      state.error = initialState.error
      const { batchId, value, category, trackId, updateStore = true } = action.meta.arg

      if (!updateStore) {
        return
      }

      state.list = state.list.map((batch) => {
        if (batch.id === batchId) {
          return {
            ...batch,
            tracks: batch.tracks.map((track) => {
              if (track.id === trackId && track.tags !== null) {
                const categoryTags = track.tags[category]
                if (categoryTags !== null && typeof categoryTags !== 'number') {
                  return {
                    ...track,
                    tags: { ...track.tags, [category]: categoryTags.filter((tag: string) => tag !== value) },
                  }
                }
              }

              return track
            }),
          }
        }
        return batch
      })
    })
    builder.addCase(removeTag.fulfilled, (state) => {
      state.error = initialState.error
    })
    builder.addCase(removeTag.rejected, (state, action) => {
      if (action.payload !== undefined) {
        const { payload } = action
        const { status, message } = payload.error
        state.error.status = status
        state.error.message = message
        const { tag } = payload
        if (payload.tag !== null) {
          const { batchId, category, trackId } = action.meta.arg
          state.list = state.list.map((batch) => {
            if (batch.id === batchId) {
              return {
                ...batch,
                tracks: batch.tracks.map((track) => {
                  if (track.id === trackId && track.tags !== null) {
                    const categoryTags = track.tags[category]
                    if (categoryTags !== null && typeof categoryTags !== 'number' && tag !== null) {
                      return { ...track, tags: { ...track.tags, [category]: [...categoryTags, tag] } }
                    }
                  }

                  return track
                }),
              }
            }
            return batch
          })
        }
      }
    })
    builder.addCase(addTag.pending, (state, action) => {
      state.error = initialState.error
      const { batchId, trackId, category, value, updateStore = true } = action.meta.arg

      if (!updateStore) {
        return
      }

      state.list = state.list.map((batch) => {
        if (batch.id === batchId) {
          return {
            ...batch,
            tracks: batch.tracks.map((track) => {
              if (track.id === trackId && track.tags !== null) {
                const categoryTags = track.tags[category] ?? []
                if (categoryTags !== null && typeof categoryTags !== 'number') {
                  return { ...track, tags: { ...track.tags, [category]: [...categoryTags, value] } }
                }
              }

              return track
            }),
          }
        }
        return batch
      })
    })
    builder.addCase(addTag.fulfilled, (state) => {
      state.error = initialState.error
    })
    builder.addCase(addTag.rejected, (state, action) => {
      if (action.payload !== undefined) {
        const { payload } = action
        const { status, message } = payload.error
        state.error.status = status
        state.error.message = message
        const { tag } = payload
        if (payload.tag !== null) {
          const { batchId, category, trackId } = action.meta.arg
          state.list = state.list.map((batch) => {
            if (batch.id === batchId) {
              return {
                ...batch,
                tracks: batch.tracks.map((track) => {
                  if (track.id === trackId && track.tags !== null) {
                    const categoryTags = track.tags[category]
                    if (categoryTags !== null && typeof categoryTags !== 'number') {
                      return {
                        ...track,
                        tags: { ...track.tags, [category]: categoryTags.filter((item) => item !== tag) },
                      }
                    }
                  }

                  return track
                }),
              }
            }
            return batch
          })
        }
      }
    })
  },
})

export const {
  trackReceivedFromApi,
  trackReceivedFromDropzone,
  trackRemove,
  deleteBatchFromStore,
  updateTrackTagsInStore,
  updateTrackFilterQuery,
  updateProgressStatus,
} = batchSlice.actions

export default batchSlice.reducer
