import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { login, logout, register, subscriptionStatus, stripeCustomerId, magicLinkLogin } from './thunks'
import { CustomError } from '../../helpers/errors'
import { ApiUserData } from '../../types/auth'
import { SiteConfig } from '@/helpers/server/api'

export type SiteFeatures = Omit<SiteConfig, 'apiKey' | 'clientId' | 'clientSecret' | 'groupCollections'>
export interface AuthState {
  userData: ApiUserData
  error: CustomError
  siteFeatures?: SiteFeatures
}

export const initialState: AuthState = {
  userData: {
    id: '',
    email: '',
    features: null,
    stripeCustomerId: null,
    trialExpiresAt: undefined,
    subscriptionStatus: null,
    stripeProductId: null,
    lastLoginAt: null,
    streamingHash: null,
  },
  error: {
    status: null,
    message: '',
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSiteFeatures: (state, action: PayloadAction<SiteFeatures>) => {
      state.siteFeatures = action.payload
    },
    clearError: (state) => {
      state.error = initialState.error
    },
    saveRemainingRequests: (state, action: PayloadAction<number>) => {
      state.userData.remainingRequests = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(magicLinkLogin.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      state.userData = payload

      localStorage.setItem(
        'userData',
        JSON.stringify({
          email: payload.email,
          id: payload.id,
          streamingHash: payload.streamingHash,
        }),
      )
    })
    builder.addCase(login.pending, (state) => {
      state.error = initialState.error
    })
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      state.userData = payload

      localStorage.setItem(
        'userData',
        JSON.stringify({
          email: payload.email,
          id: payload.id,
          streamingHash: payload.streamingHash,
        }),
      )
    })
    builder.addCase(login.rejected, (state, { payload }) => {
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
    builder.addCase(logout.rejected, (state, { payload }) => {
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
    builder.addCase(register.rejected, (state, { payload }) => {
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
    builder.addCase(stripeCustomerId.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      state.userData.stripeCustomerId = payload
    })
    builder.addCase(subscriptionStatus.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      state.userData = { ...state.userData, ...payload }
    })
  },
})

export const { clearError, saveRemainingRequests, setSiteFeatures } = authSlice.actions

export default authSlice.reducer
