import { axiosInstance } from '@/config/axios'
import { CustomError, parseErrorFromThunk } from '@/helpers/errors'
import { DetailedTrack } from '@/types/discovery/track'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { z } from 'zod'
import { RootState } from '../store'

const responseSchema = z.object({ success: z.boolean(), data: z.any() })

export const getDetailedTrack = createAsyncThunk<
  { track: DetailedTrack },
  { id: DetailedTrack['id'] },
  {
    rejectValue: CustomError
    state: RootState
  }
>('track/getDetailedTrack', async ({ id }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/track/detail/${id}`)

    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})
