import { TagsFromMapping } from '../../types/taxonomyMapping'
import { DefaultCategory } from '../../types/categories'

const isSourceIdInvalid = (categories: DefaultCategory[], sourceId: string) => {
  return !categories.some((category) => category.tags.some((tag) => tag.id === sourceId))
}

const getArray = (rule: TagsFromMapping[number]) => {
  if (Array.isArray(rule.source)) {
    return rule.source
  }
  return [rule.source]
}

export const getValidTags = (categories: DefaultCategory[], tags: TagsFromMapping) =>
  tags.filter((rule) => !getArray(rule).some((sourceId) => isSourceIdInvalid(categories, sourceId)))
