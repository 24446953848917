import { RootState } from '../store'
import { DefaultCategory, TagsWithCategories } from '../../types/categories'
import { createSelector } from 'reselect'
import { applySpecialRules } from '../../helpers/autoTagging/taxonomyMapping'

export const selectTags = (categoryId: string) =>
  createSelector(
    [selectCategoriesWithAppliedSpecialRules, (state: RootState) => state.customTaxonomy.customCategories],
    (categoriesWithAppliedSpecialRules, customCategories) => {
      let result =
        categoriesWithAppliedSpecialRules
          .find((category) => category.id === categoryId)
          ?.tags.map((tag) => ({ id: tag.id, value: tag.value, createdAt: tag.createdAt })) ?? []
      if (result.length === 0) {
        result =
          customCategories
            .find((category) => category.id === categoryId)
            ?.apiUserTags.flatMap((tag) => {
              if (!tag.enabled) {
                return []
              }
              return { id: tag.id, value: tag.value, createdAt: tag.createdAt }
            }) ?? []
      }
      return result
    },
  )

const selectCategoriesWithAppliedSpecialRules = createSelector(
  [
    (state: RootState) => state.categories.categories,
    (state: RootState) => state.apiUser.userPresets.find((preset) => preset.id === state.apiUser.currentPresetId),
    (state: RootState) => state.customTaxonomy,
  ],
  (defaultCategories, currentPreset, customTaxonomy) => {
    const isCustomTaxonomyEnabled = customTaxonomy.isMappingEnabled
    return applySpecialRules(
      defaultCategories,
      currentPreset !== undefined ? currentPreset.mapping : isCustomTaxonomyEnabled ? customTaxonomy.mapping : null,
    )
  },
)

export const selectDefaultTagsWithCategories = createSelector(
  [(state: RootState) => state.categories.categories],
  (categories) => {
    return categories.reduce<TagsWithCategories>((result, category: DefaultCategory) => {
      category.tags.forEach((tag) => {
        result.push({
          tagId: tag.id,
          tagValue: tag.value,
          categoryId: category.id,
          categoryName: category.name,
        })
      })
      return result
    }, [])
  },
)

export const selectCategoryNameById = (id: string) => (state: RootState) =>
  state.categories.categories.find((category) => category.id === id)?.name

export const selectCategories = () => (state: RootState) => state.categories.categories

export const selectIsCategoriesLoading = () => (state: RootState) => state.categories.isLoading
