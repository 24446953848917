import { Box } from '@mui/system'
import { SnackbarKey } from 'notistack'
import { useAppDispatch } from '../../store/hooks'
import { dismissNotification } from '../../store/notification/slice'

const styles = {
  pr: 1,
  fontWeight: 700,
  cursor: 'pointer',
}

interface SnackbarCloseButtonProps {
  snackbarKey: SnackbarKey
}

const SnackbarCloseButton = ({ snackbarKey }: SnackbarCloseButtonProps) => {
  const dispatch = useAppDispatch()

  return (
    <Box sx={styles} onClick={() => dispatch(dismissNotification({ key: snackbarKey }))}>
      Close
    </Box>
  )
}

export default SnackbarCloseButton
