import { z } from 'zod'

export const categorySchema = z.object({
  id: z.string(),
  priority: z.number(),
  name: z.string(),
})

export const customTagSchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime({ offset: true }),
  value: z.string(),
  enabled: z.boolean(),
  apiUserCategory: z.nullable(
    z.object({
      id: z.string(),
    }),
  ),
  category: z.nullable(
    z.object({
      id: z.string(),
    }),
  ),
})

export const defaultCategorySchema = categorySchema.extend({
  tags: z.array(
    z.object({
      id: z.string(),
      createdAt: z.string().datetime({ offset: true }),
      value: z.string(),
      supported: z.boolean(),
    }),
  ),
})

export const apiUserCategorySchema = categorySchema.extend({
  apiUserTags: z.array(customTagSchema.omit({ apiUserCategory: true, category: true })),
})
export interface UpdateTagData {
  id: string
  value?: string
  enabled?: boolean
  apiUserCategory: string | null
  category: string | null
}

export type CustomTag = z.infer<typeof customTagSchema>
export type Category = z.infer<typeof categorySchema>
export interface CategoryWithHelper extends Category {
  isCustom: boolean
}
export type DefaultCategory = z.infer<typeof defaultCategorySchema>
export type CustomCategory = z.infer<typeof apiUserCategorySchema>

export interface CustomTagDraft extends CustomTag {
  isDraft?: true
}

export interface TagWithCategory {
  tagId: string
  tagValue: string
  categoryId: string
  categoryName: string
}

export type TagsWithCategories = TagWithCategory[]
