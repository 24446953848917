import { PaletteOptions } from '@mui/material/styles'
import { grey, primary } from './general'

export const lightPalette: PaletteOptions = {
  mode: 'light',
  primary,
  secondary: {
    light: '#211D25',
    main: '#ccc',
    dark: grey[600],
  },
  success: {
    main: 'rgba(10, 190, 117, 1)',
  },
  error: {
    light: '#FEEEEE',
    main: '#F75555',
  },
  warning: {
    light: '#ff9800',
    main: '#ed6c02',
    dark: '#A35A0C',
  },
  info: {
    light: '#b3c7f0',
    main: '#0288d1',
  },
  background: {
    default: '#fff',
    // success: '#2F7D31',
    // error: '#BD0000',
  },
  text: {
    primary: grey[600],
    secondary: grey[400],
    disabled: grey[300], // 'rgba(251, 245, 255, 0.25)',
  },
  grey,
  action: {
    hover: 'rgba(150, 16, 255, 0.2)', // grey[100],
    // disabled: 'rgba(255, 255, 255, 0.90)',
    // border: '#FBF5FF1A', // ADD
  },
}
