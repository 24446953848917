import 'react'
import { Track } from '../../types/batch'

enum TrackStatus {
  UNKNOWN = 1,
  WAITING_FOR_UPLOAD = 2,
  UPLOADING = 3,
  READY_TO_TAG = 4,
  WAITING_FOR_TAGGING = 5,
  COMPLETED = 6,
  ERROR = 7,
}

export const TrackStatusMessages = {
  [TrackStatus.UNKNOWN]: 'Unknown',
  [TrackStatus.WAITING_FOR_UPLOAD]: 'Waiting...',
  [TrackStatus.UPLOADING]: 'Uploading...',
  [TrackStatus.READY_TO_TAG]: 'Ready to tag',
  [TrackStatus.WAITING_FOR_TAGGING]: 'Waiting...',
  [TrackStatus.COMPLETED]: 'Completed',
  [TrackStatus.ERROR]: 'Error',
}

const getTrackStatus = (track: Track): TrackStatus => {
  const { processedAt, queuedAt, id, duration, modelVersion, processingError } = track

  if (processingError !== null) {
    return TrackStatus.ERROR
  }

  if (processedAt !== null) {
    return TrackStatus.COMPLETED
  }

  if (queuedAt !== null) {
    return TrackStatus.WAITING_FOR_TAGGING
  }

  if (modelVersion !== null) {
    return TrackStatus.READY_TO_TAG
  }

  if (duration !== null) {
    return TrackStatus.UPLOADING
  }

  if (id.length > 0) {
    return TrackStatus.WAITING_FOR_UPLOAD
  }

  return TrackStatus.UNKNOWN
}

export { getTrackStatus, TrackStatus }
