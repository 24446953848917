import { deviceDetect, getUA } from 'react-device-detect'

// Announcement: Supported platforms chosen to support MUI 6, see: https://mui.com/material-ui/getting-started/supported-platforms/

export const isLegacyBrowser = () => {
  const device = deviceDetect(getUA)

  return (
    device.browserName === 'IE' ||
    (device.browserName === 'Edge' && parseInt(device.browserMajorVersion) < 91) ||
    (device.browserName === 'Edge Chromium' && parseInt(device.browserMajorVersion) < 91) ||
    (device.browserName === 'Firefox' && parseInt(device.browserMajorVersion) < 78) ||
    (device.browserName === 'Chrome' && parseInt(device.browserMajorVersion) < 90) ||
    (device.osName === 'iOS' && device.browserName === 'Safari' && parseFloat(device.browserFullVersion) < 12.5) ||
    (device.osName === 'Mac OS' && device.browserName === 'Safari' && parseInt(device.browserMajorVersion) < 14)
  )
}
