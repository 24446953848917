import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { grey } from '@/styles/palettes/general'

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    minHeight: '200px',
    alignItems: 'center',
  },
  icon: {
    color: grey[300],
    animationDuration: '840ms',
  },
} as const

const CustomLoading = ({ fullWidth = false, my, size }: { fullWidth?: boolean; my?: number; size?: number }) => {
  return fullWidth ? (
    <Box sx={styles.wrapper} my={my}>
      <CircularProgress size={size} thickness={4} variant="indeterminate" disableShrink sx={styles.icon} />
    </Box>
  ) : (
    <CircularProgress size={size} thickness={4} variant="indeterminate" disableShrink sx={styles.icon} />
  )
}

export default CustomLoading
