import { PaletteMode, ThemeOptions } from '@mui/material'
import localFont from 'next/font/local'
import { darkPalette } from './palettes/dark'
import { lightPalette } from './palettes/light'

export const satoshiFont = localFont({
  src: [
    {
      path: '../public/fonts/Satoshi-Light.woff2',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../public/fonts/Satoshi-LightItalic.woff2',
      weight: '300',
      style: 'italic',
    },
    {
      path: '../public/fonts/Satoshi-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/Satoshi-Italic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../public/fonts/Satoshi-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/fonts/Satoshi-MediumItalic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../public/fonts/Satoshi-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../public/fonts/Satoshi-BoldItalic.woff2',
      weight: '700',
      style: 'italic',
    },
    {
      path: '../public/fonts/Satoshi-Black.woff2',
      weight: '900',
      style: 'normal',
    },
    {
      path: '../public/fonts/Satoshi-BlackItalic.woff2',
      weight: '900',
      style: 'italic',
    },
  ],
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
  display: 'swap',
})

export const getPalette = (mode: PaletteMode = 'light') => {
  return mode === 'light' ? lightPalette : darkPalette
}

export const getThemeOptions = (mode: PaletteMode): ThemeOptions => ({
  palette: getPalette(mode),
  typography: {
    fontFamily: satoshiFont.style.fontFamily,
    h1: {
      fontWeight: 500,
      fontSize: '40px',
      lineHeight: 'normal',
    },
    h2: {
      fontWeight: 500,
      fontSize: '24px',
      letterSpacing: 0,
      lineHeight: '31px',
    },
    h3: {
      fontWeight: 500,
      fontSize: '18px',
      letterSpacing: 0,
      lineHeight: '31px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: 'normal',
    },
    button: {
      fontSize: '14px',
      fontWeight: 500,
      textTransform: 'none',
      lineHeight: 'normal',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '24px',
          lineHeight: 'normal',
          fontWeight: 500,
          paddingTop: '4px',
          alignSelf: 'center',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginTop: '5px',
          marginBottom: '16px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingBottom: '24px',
          alignSelf: 'center',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: getPalette(mode).action?.disabled,
          backdropFilter: 'blur(5px)',
          borderRadius: '10px',
          border: '1px solid',
          borderColor: getPalette(mode).text?.disabled,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '15px',
          '&.Mui-error': {
            fontWeight: 500,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            background: getPalette(mode).action?.disabled,
            backdropFilter: 'blur(25px)',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          '& .MuiButtonBase-root': {
            borderBottom: '1px solid',
            borderBottomColor: getPalette(mode).action?.disabled,
            minWidth: '195px',
          },
        },
        indicator: {
          backgroundColor: getPalette(mode).text?.primary,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: 12,
          color: getPalette(mode).text?.secondary,
          '&.Mui-selected': {
            color: getPalette(mode).text?.primary,
          },
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        style: {
          zIndex: 3000,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          color: 'black',
          borderRadius: '10px',
          filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
          '&.MuiTooltip-tooltip': {
            '&.MuiTooltip-tooltipPlacementBottom': {
              marginTop: '2px',
            },
            '&.MuiTooltip-tooltipPlacementTop': {
              marginBottom: '2px',
            },
            '&.MuiTooltip-tooltipPlacementLeft': {
              marginRight: '2px',
            },
            '&.MuiTooltip-tooltipPlacementRight': {
              marginLeft: '2px',
            },
          },
        },
      },
    },
  },
})
