import { SegmentRange } from '@/store/discovery/slice'
import { parse, toSeconds } from 'iso8601-duration'

export const timeToSeconds = (date: Date | null, ignoreHours = false): number | null => {
  if (date === null) {
    return null
  }
  const seconds = (ignoreHours ? 0 : date.getHours() * 3600) + date.getMinutes() * 60 + date.getSeconds()
  return isNaN(seconds) ? null : seconds
}

export const decorateTime = (seconds: number): string => {
  const secs = Math.round(seconds)
  return `${Math.floor(secs / 60)}:${String(secs % 60).padStart(2, '0')}`
}

/**
 * Converts a string representation of minutes and seconds (in the format "MM:SS") to the total number of seconds.
 * @param minSec The string representation of minutes and seconds.
 * @returns The total number of seconds.
 * @example
 * minSecStringToSeconds('05:30'); // Output: 330
 * minSecStringToSeconds('01:15'); // Output: 75
 */
export const minSecStringToSeconds = (minSec: string) => {
  const validMinSecStrRegEx = /^[0-9]?[0-9]:[0-5][0-9]$/
  const valid = validMinSecStrRegEx.test(minSec)
  if (!valid) {
    return 0
  }
  const parts = minSec.split(':').map(Number)
  return parts[0] * 60 + parts[1]
}

/**
 * Converts total seconds to a string representation in the format "MM:SS".
 * @param totalSeconds The total number of seconds.
 * @returns The string representation of the time in the format "MM:SS".
 */
export const secondsToMinSecString = (totalSeconds: number) => {
  const minutes = Math.floor(totalSeconds / 60)
    .toString()
    .padStart(2, '0')
  const seconds = (totalSeconds % 60).toString().padStart(2, '0')
  return `${minutes}:${seconds}`
}

export const iso8601ToSeconds = (duration: string): number => toSeconds(parse(duration))

export const today = (): Date => {
  const date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

export const thisYear = (): number => new Date().getFullYear()

export const getSegmentRangeProperty = (segmentRange: SegmentRange, propertyName: 'timeLimit' | 'timeOffset') => {
  if (segmentRange.from === null || segmentRange.to === null) {
    return undefined
  }

  if (propertyName === 'timeLimit') {
    const result = segmentRange.to - segmentRange.from
    return result > 60 ? undefined : Math.floor(result)
  }

  // timeOffset
  return Math.floor(segmentRange.from)
}
