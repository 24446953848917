import { axiosInstance } from '@/config/axios'
import { CustomError, parseErrorFromThunk } from '@/helpers/errors'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { CollectionResponse, SimilarCollectionsResponse } from '@aims-api/aims-node/dist/helpers/types/collection'
import { z } from 'zod'
import { RootState } from '../store'
import { DetailedTrack } from '@/types/discovery/track'

const responseSchema = z.object({ success: z.boolean(), data: z.any() })

export const getAlbum = createAsyncThunk<
  CollectionResponse,
  { key: string },
  {
    rejectValue: CustomError
    state: RootState
  }
>('album/getAlbum', async ({ key }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/album/${key}`)

    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getTracksOfAlbum = createAsyncThunk<
  { tracks: DetailedTrack[] },
  { key: string },
  {
    rejectValue: CustomError
    state: RootState
  }
>('album/getTracksOfAlbum', async ({ key }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/album/${key}/tracks`)
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getSimilarAlbums = createAsyncThunk<
  SimilarCollectionsResponse,
  { key: string; page: number },
  {
    rejectValue: CustomError
    state: RootState
  }
>('album/getSimilarAlbums', async ({ key, page }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/album/${key}/similar?page=${page}`)
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})
