import { z } from 'zod'

const ignoreMode = z.object({
  mode: z.literal('ignore'),
  source: z.array(z.string()),
  enabled: z.boolean().default(true),
})
const addMode = z.object({
  mode: z.literal('add'),
  enabled: z.boolean().default(true),
  source: z.array(z.string()),
  destination: z.array(
    z.object({
      category: z.string(),
      title: z.string(),
    }),
  ),
})
const moveMode = z.object({
  mode: z.literal('move'),
  enabled: z.boolean().default(true),
  source: z.array(z.string()),
  destination: z.object({
    category: z.string(),
  }),
})

const replaceMode = z.object({
  mode: z.literal('replace'),
  enabled: z.boolean().default(true),
  source: z.array(z.string()),
  destination: z.array(
    z.object({
      category: z.string(),
      title: z.string(),
    }),
  ),
})

export const mappingTagsSchema = z.discriminatedUnion('mode', [ignoreMode, addMode, moveMode, replaceMode])

const categoryMappingSchema = z.record(
  z.string(),
  z.object({
    enabled: z.boolean(),
    title: z.string(),
  }),
)

export const tagsSchema = z.preprocess((tags) => (tags === null ? [] : tags), z.array(mappingTagsSchema))

export const mappingSchema = z.nullable(
  z.object({
    categories: categoryMappingSchema,
    tags: tagsSchema,
  }),
)

export const taxonomyMappingSchema = z.object({
  mapping: mappingSchema,
  isMappingEnabled: z.boolean(),
})

export type CategoryMapping = z.infer<typeof categoryMappingSchema>
export type Mapping = z.infer<typeof mappingSchema>
export type TagsMapping = z.infer<typeof mappingTagsSchema>
export type TaxonomyMapping = z.infer<typeof taxonomyMappingSchema>
export type TagsFromMapping = z.infer<typeof tagsSchema>
export type TagsMappingIgnore = z.infer<typeof ignoreMode>
export type TagsMappingMove = z.infer<typeof moveMode>
export type TagsMappingReplace = z.infer<typeof replaceMode>
export type TagsMappingAdd = z.infer<typeof addMode>
