import { getValues } from '@/helpers/utils'
import { AutocompleteResponse } from '@aims-api/aims-node/dist/helpers/types/search'
import { SearchResponse } from '@aims-api/aims-node/dist/endpoints/search'
import { z } from 'zod'
import { TrackField } from './discovery/track'
import { trackDetailedSchema } from '@aims-api/aims-node/dist/helpers/types/track'

export const DiscoveryInputs = {
  INTERNAL: 'id',
  LINK: 'url', // soundcloud, spotify, tiktok, vimeo, youtube, apple music
  FILE_LINK: 'file-url',
  UPLOAD: 'audio-file',
  TEXT: 'text', // tag <= 2 words
  TEXT_PROMPT: 'text-prompt',
  FILTERS_ONLY: 'filters-only',
} as const

export const LinkSources = {
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  SOUNDCLOUD: 'soundcloud',
  SPOTIFY: 'spotify',
  APPLE_MUSIC: 'apple',
  TIKTOK: 'tiktok',
} as const

export type LinkSource = (typeof LinkSources)[keyof typeof LinkSources]

export const linkInfoSchema = z.object({
  audioUrl: z.optional(z.nullable(z.string())),
  description: z.optional(z.string()),
  duration: z.number(),
  embed: z.optional(z.string()),
  id: z.string(), // Announcement: link id
  thumbnail: z.optional(z.string()),
  title: z.optional(z.string()),
  type: z.enum(getValues(LinkSources)),
})

export type LinkInfo = z.infer<typeof linkInfoSchema>

export type ExternalTrack = Omit<LinkInfo, 'description' | 'embed' | 'type'> & { type?: LinkInfo['type'] }

const schemaDiscoverySearch = z.object({
  title: z.string(),
  input: z.enum(getValues(DiscoveryInputs)),
  link: z.optional(z.string()),
  linkInfo: z.optional(linkInfoSchema),
  hash: z.optional(z.string()),
  uploadInfo: z.optional(
    z.object({
      format: z.string(),
      duration: z.number(),
      audioUrl: z.string(), // Announcement: local browser url
    }),
  ),
  internalTrack: z.optional(trackDetailedSchema.merge(z.object({ audioUrl: z.string() }))),
  isSegmentToolAvailable: z.optional(z.boolean()),
})

export type DiscoverySearch = z.infer<typeof schemaDiscoverySearch> & {
  didYouMean?: SearchResponse['did_you_mean']
  unifiedSearchType?: SearchResponse['type']
  lyricsSearch?: { active: boolean; terms: string[] }
}
export const schemaRecentSearches = z.array(schemaDiscoverySearch)

export interface SearchParams {
  prioritise_bpm: boolean
  suppress_vocals: boolean
}

export const initialSearchParams: SearchParams = {
  prioritise_bpm: false, // snake case used by API
  suppress_vocals: false, // snake case used by API
}

export interface ApiUserSearch {
  userId: string
  search: DiscoverySearch
}

interface FieldValueInteger {
  field: 'id' | 'duration' | 'release_year' | 'bpm'
  value: number
}

export interface FieldValueBoolean {
  value: boolean
  field:
    | 'version'
    | 'active'
    | 'licensable'
    | 'restricted'
    | 'explicit'
    | 'profane'
    | 'commercial'
    | 'sync_history'
    | 'recognisable'
    | 'jam_sync'
    | 'hit'
    | 'top'
}

interface FieldValueString {
  field: TrackField
  value: string
}

type RuleInteger = { operator: 'gt' | 'gte' | 'gten' | 'lt' | 'lte' } & FieldValueInteger
type RuleString = {
  operator: 'begins' | 'contains' | 'not-contains' | 'ends' | 'empty' | 'not-empty' | 'in'
} & FieldValueString
type RuleGeneral = {
  operator: 'eq' | 'neq' | 'null' | 'not-null'
} & (FieldValueInteger | FieldValueBoolean | FieldValueString)

export type Rule = RuleInteger | RuleString | RuleGeneral
export interface Filter {
  logic: 'and' | 'or'
  conditions: Array<Rule | Filter>
}

export type Suggestion = AutocompleteResponse['suggestions'][0]
