import { CustomError } from '@/helpers/errors'
import { DetailedTrack } from '@/types/discovery/track'
import { createSlice } from '@reduxjs/toolkit'
import { getDetailedTrack } from './thunks'

export interface PlaylistState {
  all: Record<DetailedTrack['id'], DetailedTrack>
  lyricsFocused: boolean
  error: CustomError
}

const initialState: PlaylistState = {
  all: {},
  lyricsFocused: false,
  error: {
    status: null,
    message: '',
  },
}

export const playlistSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    setLyricsFocused: (state, action) => {
      state.lyricsFocused = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDetailedTrack.pending, (state, { meta }) => {
      state.error = initialState.error
    })
    builder.addCase(getDetailedTrack.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      const track = payload.track
      state.all = {
        ...state.all,
        [track.id]: track,
      }
    })
    builder.addCase(getDetailedTrack.rejected, (state, { payload, meta }) => {
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
    })
  },
})

export const { setLyricsFocused } = playlistSlice.actions

export default playlistSlice.reducer
