import { createAsyncThunk } from '@reduxjs/toolkit'
import { CustomError, parseErrorFromThunk } from '../../helpers/errors'
import { axiosInstance } from '../../config/axios'
import { z } from 'zod'
import { errors } from '../../consts/errors'
import { RootState } from '../store'
import { saveRemainingRequests } from '../auth/slice'
import { selectRemainingRequests } from '../auth/selectors'
import { TaxonomyPreset, taxonomyPresetListSchema } from '../../types/taxonomyPreset'

const applyPromoCodeResponseSchema = z.object({
  remainingMonthlyRequests: z.number(),
})

export const changePassword = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  string,
  {
    rejectValue: CustomError
  }
>('api-user/changePassword', (password, thunkApi) => {
  try {
    void axiosInstance.post(`/api/api-user/change-password`, { password })
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const stripeAccountData = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  string,
  {
    rejectValue: CustomError
  }
>('api-user/stripeAccountData', (stripeCustomerId, thunkApi) => {
  try {
    void axiosInstance.post(`/api/api-user/stripe-account-data`, { id: stripeCustomerId })
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const applyPromoCode = createAsyncThunk<
  { creditsAdded: number },
  string,
  {
    rejectValue: CustomError
    state: RootState
  }
>('api-user/applyPromoCode', async (code, thunkApi) => {
  try {
    const response = await axiosInstance.post(`/api/api-user/apply-promo-code`, { code })
    const parserResponse = applyPromoCodeResponseSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }

    const remainingRequests = selectRemainingRequests(thunkApi.getState()) ?? 0
    void thunkApi.dispatch(saveRemainingRequests(parserResponse.data.remainingMonthlyRequests))
    return {
      creditsAdded: parserResponse.data.remainingMonthlyRequests - remainingRequests,
    }
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getUserPresets = createAsyncThunk<
  TaxonomyPreset[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
  }
>('taxonomy-preset/getUserPresets', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get('/api/api-user/get-taxonomy-presets')
    const parserResponse = taxonomyPresetListSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})
