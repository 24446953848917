export const errors = {
  INTERNAL_ERROR: {
    status: 500,
    message: 'Internal error',
  },
  OPERATION_FAILED_RETRY: {
    status: 500,
    message: `Operation has failed. Please, try again later.`,
  },
  COULD_NOT_FETCH_LINK_INFO: {
    status: 500,
    message: `Error while fetching external track info`,
  },
  PARSER: {
    status: 500,
    message: 'Error while parsing response from API',
  },
  METHOD_NOT_ALLOWED: {
    status: 405,
    message: 'Method not allowed',
  },
  BAD_REQUEST: {
    status: 400,
    message: 'Invalid request received',
  },
  BATCH_NOT_FOUND: {
    status: 404,
    message: 'Requested batch does not exist',
  },
  NO_RESULTS: {
    status: 404,
    message: 'No results found',
  },
}
