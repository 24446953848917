import { DefaultCategory } from '../../types/categories'
import {
  Mapping,
  TagsMappingAdd,
  TagsMappingIgnore,
  TagsMappingMove,
  TagsMappingReplace,
} from '../../types/taxonomyMapping'

export const applySpecialRules = (categories: DefaultCategory[], mapping?: Mapping) => {
  if (mapping === null || mapping === undefined) {
    return categories
  }
  let result = [...categories]
  // update tags
  for (const tagMapping of mapping.tags) {
    if (tagMapping.mode === 'ignore') {
      result = applyIgnoreMapping(tagMapping, result)
    }

    if (tagMapping.mode === 'replace') {
      result = applyReplaceMapping(tagMapping, result)
    }

    if (tagMapping.mode === 'move') {
      result = applyMoveMapping(tagMapping, result)
    }

    if (tagMapping.mode === 'add') {
      result = applyAddMapping(tagMapping, result)
    }
  }

  // disable categories
  result = result.flatMap((category) => {
    if (!mapping.categories[category.id]?.enabled) {
      return []
    }
    return { ...category, name: mapping.categories[category.id].title }
  })

  return result
}

export const applyIgnoreMapping = (mapping: TagsMappingIgnore, categories: DefaultCategory[]) => {
  return categories.map((category) => {
    if (category.tags.some((tag) => mapping.source.includes(tag.id))) {
      return { ...category, tags: category.tags.filter((tag) => !mapping.source.includes(tag.id)) }
    }
    return category
  })
}

export const applyReplaceMapping = (mapping: TagsMappingReplace, categories: DefaultCategory[]) => {
  // remove sources
  const result = categories.map((category) => {
    return { ...category, tags: category.tags.filter((tag) => !mapping.source.includes(tag.id)) }
  })

  // add replaces
  return mapping.destination.reduce<DefaultCategory[]>((reduceTemp, destination) => {
    return reduceTemp.map((category) => {
      if (category.id === destination.category) {
        return {
          ...category,
          tags: [...category.tags, { id: destination.title, value: destination.title, createdAt: '', supported: true }],
        }
      }
      return category
    })
  }, result)
}

export const applyMoveMapping = (mapping: TagsMappingMove, categories: DefaultCategory[]) => {
  const movedTags: DefaultCategory['tags'] = []

  // finds moved tags, saves them and then removes sources from default
  let result = categories.map((category) => {
    if (movedTags.length < mapping.source.length) {
      mapping.source.forEach((tagId) => {
        const tag = category.tags.find((tag) => tag.id === tagId)
        if (tag !== undefined) {
          movedTags.push(tag)
        }
      })
    }

    return { ...category, tags: category.tags.filter((tag) => !mapping.source.includes(tag.id)) }
  })

  // only one destination exists
  result = result.map((category) => {
    if (category.id === mapping.destination.category) {
      return {
        ...category,
        tags: [...category.tags, ...movedTags],
      }
    }
    return category
  })

  return result
}
export const applyAddMapping = (mapping: TagsMappingAdd, categories: DefaultCategory[]) => {
  let result = [...categories]
  mapping.destination.forEach((destination) => {
    result = result.map((category) => {
      if (category.id === destination.category) {
        return {
          ...category,
          tags: [...category.tags, { id: destination.title, value: destination.title, createdAt: '', supported: true }],
        }
      }
      return category
    })
  })

  return result
}
