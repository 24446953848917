import { FreeTrial, SubscriptionStatus } from '../types/subscription'

export const getFreeTrial = (
  trialExpiresAt: string | null | undefined,
  subscriptionStatus: string | null,
): FreeTrial => {
  const today = new Date()
  if (trialExpiresAt === null || trialExpiresAt === undefined) {
    return {
      expired: false,
      trialExpiresAt,
      active: false,
    }
  }

  const trialExpiresAtDate = new Date(trialExpiresAt)
  if (trialExpiresAtDate > today && subscriptionStatus === null) {
    return {
      expired: false,
      trialExpiresAt,
      active: true,
    }
  }
  return { expired: true, trialExpiresAt, active: false }
}

export const getShouldBuySubscription = (status: string | null, trialExpiresAt: string | null | undefined) => {
  return status === SubscriptionStatus.SUSPENDED || (status === null && getFreeTrial(trialExpiresAt, status).expired)
}
