import { DeletingStatus, LoadingStatus, UpdatingStatus } from '@/consts/common'
import { CustomError } from '@/helpers/errors'
import { Track } from '@/types/batch'
import { ExternalTrack } from '@/types/discovery'
import { DetailedTrack } from '@/types/discovery/track'
import { Project } from '@/types/project'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  addSuggestedTrack,
  addTrackToProject,
  cloneSnapshot,
  createProject,
  createSnapshot,
  deleteProject,
  getProjectList,
  getSuggestionsForProject,
  getTracksOfProject,
  refreshSuggestionsForProject,
  removeTrack,
  updateProject,
} from './thunks'
import { PopperPlacementType } from '@mui/material/Popper'

interface TracksObject {
  tracks: DetailedTrack[]
  loadingStatus: (typeof LoadingStatus)[keyof typeof LoadingStatus]
  awaitingTrack?: boolean
}

export const initialTracksObject = {
  tracks: [] as DetailedTrack[],
  loadingStatus: LoadingStatus.IDLE,
  awaitingTrack: false,
}
interface SuggestionsObject {
  fetchedTracks?: DetailedTrack[]
  usedTrackIds: Array<DetailedTrack['id']>
  tracksToShow: DetailedTrack[]
  loadingStatus: (typeof LoadingStatus)[keyof typeof LoadingStatus]
  page: number
}
export interface ProjectState {
  list: Project[]
  listLoadingStatus: (typeof LoadingStatus)[keyof typeof LoadingStatus]
  updatingStatusMap: Record<Project['id'], (typeof UpdatingStatus)[keyof typeof UpdatingStatus]>
  deletingStatusMap: Record<Project['id'], (typeof DeletingStatus)[keyof typeof DeletingStatus]>
  tracksMap: Record<Project['id'], TracksObject>
  suggestionsMap: Record<Project['id'], SuggestionsObject>
  tempTrackToBeAdded?: DetailedTrack // Announcement: addTrackToProject popover and drag & drop functionality
  addTrackPopper?: {
    open: boolean
    anchorId: string
    placement?: PopperPlacementType
  }
  snapshot?: {
    projectId: Project['id']
    id?: string
    tracks?: DetailedTrack[]
  }
  trackActionsMenuOpenForTrackId?: string
  error: CustomError
}

const initialState: ProjectState = {
  list: [],
  listLoadingStatus: LoadingStatus.IDLE,
  updatingStatusMap: {},
  deletingStatusMap: {},
  tracksMap: {},
  suggestionsMap: {},
  error: {
    status: null,
    message: '',
  },
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    toggleTrackActionsMenu(state, action: PayloadAction<string | undefined>) {
      if (action.payload === undefined) {
        // case: onHide effect
        state.addTrackPopper = undefined
      }
      state.trackActionsMenuOpenForTrackId = action.payload
    },
    openAddTrackPopper(
      state,
      action: PayloadAction<{
        anchorId: string
        placement?: PopperPlacementType
        tempTrack: Track | DetailedTrack | ExternalTrack | null
      }>,
    ) {
      state.addTrackPopper = {
        open: true,
        anchorId: action.payload.anchorId,
        placement: action.payload.placement,
      }
      if (action.payload.tempTrack === null) {
        return
      }
      state.tempTrackToBeAdded = action.payload.tempTrack as DetailedTrack
    },
    clearAddTrackPopper(state) {
      state.addTrackPopper = undefined
    },
    openShareSnapshotModal(state, action: PayloadAction<Project['id']>) {
      state.snapshot = {
        projectId: action.payload,
      }
    },
    addTracksToSnapshot(state, action: PayloadAction<DetailedTrack[]>) {
      if (state.snapshot === undefined) {
        state.snapshot = {
          projectId: 'snapshot-page',
          tracks: action.payload,
        }
        return
      }

      state.snapshot?.tracks?.push(...action.payload)
    },
    clearSnapshot(state) {
      state.snapshot = undefined
    },
    setTempTrackToBeAdded(state, action: PayloadAction<DetailedTrack | undefined>) {
      state.tempTrackToBeAdded = action.payload
    },
    deleteProjectFromStore(state, action: PayloadAction<string>) {
      state.list = state.list.filter((project) => project.id !== action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectList.pending, (state) => {
      if (state.listLoadingStatus === LoadingStatus.IDLE && state.list.length > 0) {
        state.list = []
      }
      state.error = initialState.error
      state.listLoadingStatus = LoadingStatus.LOADING
    })
    builder.addCase(getProjectList.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      state.listLoadingStatus = LoadingStatus.LOADED
      state.list = payload
      return state
    })
    builder.addCase(getProjectList.rejected, (state, { payload }) => {
      if (payload !== undefined) {
        state.error.message = payload.message ?? payload.payload?.message
      }
      state.listLoadingStatus = LoadingStatus.ERROR
    })
    builder.addCase(createProject.fulfilled, (state, { payload }) => {
      state.list.unshift(payload)
      state.error = initialState.error
    })
    builder.addCase(createProject.rejected, (state, { payload }) => {
      if (payload !== undefined) {
        state.error.message = payload.message ?? payload.payload?.message
      }
    })
    builder.addCase(updateProject.pending, (state, { meta }) => {
      state.error = initialState.error
      state.updatingStatusMap[meta.arg.id] = UpdatingStatus.UPDATING
    })
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      state.list = state.list.map((project) => (project.id === payload.id ? { ...project, ...payload } : project))
      state.updatingStatusMap[payload.id] = UpdatingStatus.UPDATED
    })
    builder.addCase(updateProject.rejected, (state, { payload, meta }) => {
      if (payload !== undefined) {
        state.error.message = payload.message ?? payload.payload?.message
      }
      state.updatingStatusMap[meta.arg.id] = UpdatingStatus.ERROR
    })
    builder.addCase(deleteProject.pending, (state, { meta }) => {
      state.error = initialState.error
      state.deletingStatusMap[meta.arg] = DeletingStatus.DELETING
    })
    builder.addCase(deleteProject.fulfilled, (state, { meta }) => {
      state.error = initialState.error
      const projectId = meta.arg
      state.list = state.list.filter((project) => project.id !== projectId)
      state.deletingStatusMap[projectId] = DeletingStatus.DELETED
    })
    builder.addCase(deleteProject.rejected, (state, { payload }) => {
      if (payload !== undefined) {
        state.error.message = payload.message ?? payload.payload?.message
      }
    })
    builder.addCase(getTracksOfProject.pending, (state, { meta }) => {
      const projectId = meta.arg
      state.error = initialState.error
      state.tracksMap[projectId] = {
        tracks: [],
        loadingStatus: LoadingStatus.LOADING,
      }
    })
    builder.addCase(getTracksOfProject.fulfilled, (state, { payload, meta }) => {
      const projectId = meta.arg
      state.list = state.list.map((project) =>
        project.id === projectId ? { ...project, number_of_tracks: payload.tracks.length } : project,
      )
      state.tracksMap[projectId] = {
        tracks: payload.tracks,
        loadingStatus: LoadingStatus.LOADED,
      }
    })
    builder.addCase(getTracksOfProject.rejected, (state, { payload, meta }) => {
      const projectId = meta.arg
      if (payload !== undefined) {
        state.error.message = payload.message ?? payload.payload?.message
      }
      state.tracksMap[projectId] = {
        ...state.tracksMap[projectId],
        loadingStatus: LoadingStatus.ERROR,
      }
    })

    builder.addCase(getSuggestionsForProject.pending, (state, { meta }) => {
      const projectId = meta.arg
      state.error = initialState.error
      state.suggestionsMap[projectId] = {
        fetchedTracks: [],
        usedTrackIds: [],
        tracksToShow: [],
        loadingStatus: LoadingStatus.LOADING,
        page: 1,
      }
    })
    builder.addCase(getSuggestionsForProject.fulfilled, (state, { payload, meta }) => {
      const projectId = meta.arg
      state.suggestionsMap[projectId] = {
        ...state.suggestionsMap[projectId],
        fetchedTracks: payload.tracks,
        tracksToShow: payload.tracks,
        loadingStatus: LoadingStatus.LOADED,
        page: 1,
      }
    })
    builder.addCase(getSuggestionsForProject.rejected, (state, { payload, meta }) => {
      const projectId = meta.arg
      if (payload !== undefined) {
        state.error.message = payload.message ?? payload.payload?.message
      }
      state.suggestionsMap[projectId] = {
        ...state.suggestionsMap[projectId],
        loadingStatus: LoadingStatus.ERROR,
      }
    })

    builder.addCase(refreshSuggestionsForProject.pending, (state, { meta }) => {
      const { projectId } = meta.arg
      state.error = initialState.error
      state.suggestionsMap[projectId] = {
        ...state.suggestionsMap[projectId],
        loadingStatus: LoadingStatus.LOADING,
      }
    })
    builder.addCase(refreshSuggestionsForProject.fulfilled, (state, { payload, meta }) => {
      const { projectId, page } = meta.arg
      const fetchedTracks = payload.tracks
      const previouslyShownTracks = state.suggestionsMap[projectId].tracksToShow.slice(0, 3)
      const usedTrackIds = [
        ...state.suggestionsMap[projectId].usedTrackIds,
        ...previouslyShownTracks.map((track) => track.id),
      ]
      state.suggestionsMap[projectId] = {
        fetchedTracks,
        usedTrackIds,
        tracksToShow: fetchedTracks.filter((track) => !usedTrackIds.includes(track.id)),
        loadingStatus: LoadingStatus.LOADED,
        page: page ?? 1,
      }
    })
    builder.addCase(refreshSuggestionsForProject.rejected, (state, { payload, meta }) => {
      const { projectId } = meta.arg
      if (payload !== undefined) {
        state.error.message = payload.message ?? payload.payload?.message
      }
      state.suggestionsMap[projectId] = {
        ...state.suggestionsMap[projectId],
        loadingStatus: LoadingStatus.ERROR,
      }
    })

    builder.addCase(addSuggestedTrack.pending, (state, { meta }) => {
      const { project } = meta.arg
      state.updatingStatusMap[project.id] = UpdatingStatus.UPDATING
      state.tracksMap[project.id] = {
        ...state.tracksMap[project.id],
        awaitingTrack: true,
      }
    })
    builder.addCase(addSuggestedTrack.fulfilled, (state, { meta }) => {
      const { track, project } = meta.arg

      const tracksOfProject = state.tracksMap[project.id]
      state.tracksMap[project.id] = {
        ...tracksOfProject,
        tracks: [...tracksOfProject.tracks, track],
        awaitingTrack: false,
      }

      const suggestions = state.suggestionsMap[project.id]
      const usedTrackIds = [...suggestions.usedTrackIds, track.id]
      const tracksToShow = suggestions.fetchedTracks?.filter((track) => !usedTrackIds.includes(track.id))
      state.suggestionsMap[project.id] = {
        ...suggestions,
        usedTrackIds,
        tracksToShow: tracksToShow ?? [],
      }

      state.updatingStatusMap[project.id] = UpdatingStatus.UPDATED
      state.list = state.list.map((item) =>
        item.id === project.id ? { ...item, number_of_tracks: item.number_of_tracks + 1 } : item,
      )
    })
    builder.addCase(addSuggestedTrack.rejected, (state, { payload, meta }) => {
      if (payload !== undefined) {
        state.error.message = payload.message ?? payload.payload?.message
      }
      const { project } = meta.arg
      state.tracksMap[project.id] = {
        ...state.tracksMap[project.id],
        awaitingTrack: false,
      }
      state.updatingStatusMap[project.id] = UpdatingStatus.ERROR
    })

    builder.addCase(addTrackToProject.pending, (state, { meta }) => {
      state.trackActionsMenuOpenForTrackId = undefined
      const { projectId } = meta.arg
      state.updatingStatusMap[meta.arg.projectId] = UpdatingStatus.UPDATING
      state.tracksMap[projectId] = {
        ...state.tracksMap[projectId],
        awaitingTrack: true,
      }
    })
    builder.addCase(addTrackToProject.fulfilled, (state, action) => {
      const { track, projectId } = action.payload ?? {}
      if (track === undefined || projectId === undefined) {
        return
      }

      const tracksObject = state.tracksMap[projectId]
      const tracks = tracksObject.tracks ?? []
      state.tracksMap[projectId] = {
        ...tracksObject,
        tracks: [track, ...tracks],
        awaitingTrack: false,
      }

      state.updatingStatusMap[projectId] = UpdatingStatus.UPDATED
      state.tempTrackToBeAdded = undefined
      state.list = state.list.map((item) =>
        item.id === projectId ? { ...item, number_of_tracks: (item.number_of_tracks ?? 0) + 1 } : item,
      )
    })
    builder.addCase(addTrackToProject.rejected, (state, { meta }) => {
      state.updatingStatusMap[meta.arg.projectId] = UpdatingStatus.ERROR
      state.tracksMap[meta.arg.projectId] = {
        ...state.tracksMap[meta.arg.projectId],
        awaitingTrack: false,
      }
    })

    builder.addCase(removeTrack.pending, (state, { meta }) => {
      state.updatingStatusMap[meta.arg.projectId] = UpdatingStatus.UPDATING

      const { projectId, trackIdClient } = meta.arg
      const tracksOfProject = state.tracksMap[projectId]

      state.tracksMap[projectId] = {
        ...tracksOfProject,
        tracks: tracksOfProject.tracks?.filter((track) => track.id_client !== trackIdClient),
      }

      state.list = state.list.map((item) =>
        item.id === projectId ? { ...item, number_of_tracks: item.number_of_tracks - 1 } : item,
      )
    })
    builder.addCase(removeTrack.fulfilled, (state, { meta }) => {
      state.updatingStatusMap[meta.arg.projectId] = UpdatingStatus.UPDATED
    })
    builder.addCase(removeTrack.rejected, (state, { meta }) => {
      state.updatingStatusMap[meta.arg.projectId] = UpdatingStatus.ERROR
    })
    builder.addCase(createSnapshot.fulfilled, (state, { payload }) => {
      if (payload.projectId === state.snapshot?.projectId) {
        state.snapshot = payload
        return
      }
      state.snapshot = undefined
    })
    builder.addCase(cloneSnapshot.fulfilled, (state, { payload }) => {
      state.list.splice(0, 0, payload)
    })
  },
})

export const {
  deleteProjectFromStore,
  setTempTrackToBeAdded,
  openAddTrackPopper,
  clearAddTrackPopper,
  openShareSnapshotModal,
  clearSnapshot,
  addTracksToSnapshot,
  toggleTrackActionsMenu,
} = projectSlice.actions

export default projectSlice.reducer
