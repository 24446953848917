import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { errors } from '../../consts/errors'
import { CustomError, parseErrorFromThunk } from '../../helpers/errors'
import { axiosInstance } from '../../config/axios'
import { NotificationStatus } from '../notification/slice'
import {
  Category,
  CustomTag,
  UpdateTagData,
  categorySchema,
  apiUserCategorySchema,
  customTagSchema,
  CustomCategory,
} from '../../types/categories'
import { z } from 'zod'
import { addNotification } from '../notification/thunks'
import { Mapping, TaxonomyMapping, taxonomyMappingSchema } from '../../types/taxonomyMapping'
import { selectCategories } from '../categories/selectors'
import { getValidTags } from './helpers'

export const getCustomCategoryList = createAsyncThunk<
  CustomCategory[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
  }
>('custom-taxonomy/get-category-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get('/api/custom-taxonomy/get-category-list')
    const parserResponse = z.array(apiUserCategorySchema).safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const createCustomCategory = createAsyncThunk<
  Category,
  Omit<Category, 'id'>,
  {
    rejectValue: CustomError
  }
>('custom-taxonomy/create-category', async (category, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/custom-taxonomy/create-category', category)

    const parserResponse = categorySchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const updateCustomCategory = createAsyncThunk<
  Category,
  { id: string; name?: string; priority?: number },
  {
    rejectValue: CustomError
  }
>('custom-taxonomy/update-category', async (category, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/custom-taxonomy/update-category', {
      id: category.id,
      data: {
        name: category?.name,
        priority: category?.priority,
      },
    })

    const parserResponse = categorySchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const deleteCustomCategory = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  string,
  {
    rejectValue: CustomError
    state: RootState
  }
>('custom-taxonomy/delete-category', async (categoryId, thunkApi) => {
  try {
    const response = await axiosInstance.delete(`/api/custom-taxonomy/delete-category?id=${categoryId}`)

    const parserResponse = categorySchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: `Unexpected error, please try again`,
      }),
    )
    return thunkApi.rejectWithValue(customError)
  }
})

export const getCustomTagList = createAsyncThunk<
  CustomTag[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
  }
>('custom-taxonomy/get-tag-list', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get('/api/custom-taxonomy/get-tag-list')
    const parserResponse = z.array(customTagSchema).safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const createCustomTag = createAsyncThunk<
  CustomTag,
  {
    value: string
    apiUserCategory?: string
    category?: string
  },
  {
    rejectValue: CustomError
  }
>('custom-taxonomy/create-tag', async (tagWithCategory, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/custom-taxonomy/create-tag', tagWithCategory)

    const parserResponse = customTagSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const updateCustomTag = createAsyncThunk<
  CustomTag,
  UpdateTagData,
  {
    rejectValue: CustomError
  }
>('custom-taxonomy/update-tag', async (data, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/custom-taxonomy/update-tag', {
      id: data.id,
      data: {
        apiUserCategory: data.apiUserCategory,
        category: data.category,
        value: data?.value,
        enabled: data?.enabled,
      },
    })

    const parserResponse = customTagSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const deleteCustomTag = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  string,
  {
    rejectValue: CustomError
    state: RootState
  }
>('custom-taxonomy/delete-tag', async (tagId, thunkApi) => {
  try {
    await axiosInstance.delete(`/api/custom-taxonomy/delete-tag?id=${tagId}`)
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: `Unexpected error, please try again`,
      }),
    )
    return thunkApi.rejectWithValue(customError)
  }
})

export const getMapping = createAsyncThunk<
  TaxonomyMapping,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
    state: RootState
  }
>('custom-taxonomy/get-mapping', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get('/api/custom-taxonomy/get-mapping')
    const parserResponse = taxonomyMappingSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      void thunkApi.dispatch(
        addNotification({
          variant: NotificationStatus.ERROR,
          message: 'Taxonomy mapping returned wrong data from API.',
        }),
      )
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    const categories = selectCategories()(thunkApi.getState())
    if (parserResponse.data.mapping === null) {
      return parserResponse.data
    }
    const validTags = getValidTags(categories, parserResponse.data.mapping.tags)
    return {
      ...parserResponse.data,
      mapping: {
        ...parserResponse.data.mapping,
        tags: validTags,
      },
    }
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const updateMapping = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  { mapping?: Mapping; enabled: boolean },
  {
    rejectValue: CustomError
    state: RootState
  }
>('custom-taxonomy/update-mapping', async (taxonomyMapping, thunkApi) => {
  try {
    await axiosInstance.post('/api/custom-taxonomy/update-mapping', taxonomyMapping)

    void thunkApi.dispatch(
      addNotification({
        variant: taxonomyMapping.enabled ? NotificationStatus.SUCCESS : NotificationStatus.INFO,
        message:
          taxonomyMapping.mapping === undefined
            ? taxonomyMapping.enabled
              ? 'Taxonomy mapping was enabled'
              : 'Taxonomy mapping was disabled'
            : `Taxonomy mapping was updated`,
      }),
    )

    void thunkApi.dispatch(getMapping())
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message:
          customError.message.indexOf('mapping rule') !== undefined
            ? customError.message
            : `Unexpected error, please try again`,
      }),
    )
    return thunkApi.rejectWithValue(customError)
  }
})
