import { PaletteColor } from '@mui/material'

export const grey = {
  100: '#F0EFF5',
  200: '#D9D7E1',
  300: '#B3B0BD',
  400: '#8A8694',
  500: '#5A5663',
  600: '#2C2733',
  700: '#17131E',
  800: '#0F0A15',
  900: '#0B0711',
}

export const primary: PaletteColor = {
  main: '#8137CF',
  light: '#E8D6FA',
  dark: '#5A00C3',
  contrastText: '#fff',
}

export const primaryOpacity = 'rgba(150, 16, 255, 0.2)'
