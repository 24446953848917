import { axiosInstance } from '@/config/axios'
import { CustomError, parseErrorFromThunk } from '@/helpers/errors'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CollectionResponse,
  CollectionsList,
  SimilarCollectionsResponse,
} from '@aims-api/aims-node/dist/helpers/types/collection'
import { z } from 'zod'
import { RootState } from '../store'
import { DetailedTrack } from '@/types/discovery/track'
import { selectUserId } from '../auth/selectors'

const responseSchema = z.object({ success: z.boolean(), data: z.any() })

export const getPlaylist = createAsyncThunk<
  CollectionResponse,
  { key: string },
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getPlaylist', async ({ key }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/playlist/${key}`)

    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getPlaylistList = createAsyncThunk<
  CollectionsList['collections'],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getProjectList', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get('/api/playlist/list', {
      params: { user_id: selectUserId(thunkApi.getState()) },
    })

    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data.collections
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getTracksOfPlaylist = createAsyncThunk<
  { tracks: DetailedTrack[] },
  { key: string },
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getTracksOfPlaylist', async ({ key }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/playlist/${key}/tracks`)
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

const SUGGESTIONS_PAGE_SIZE = 10

export const getSuggestedTracksForPlaylist = createAsyncThunk<
  { tracks: DetailedTrack[] },
  { key: string; page: number },
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getSuggestedTracksForPlaylist', async ({ key, page }, thunkApi) => {
  try {
    const response = await axiosInstance.get(
      `/api/playlist/${key}/suggest-tracks?page=${page}&page_size=${SUGGESTIONS_PAGE_SIZE}`,
    )
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getSimilarPlaylists = createAsyncThunk<
  SimilarCollectionsResponse,
  { key: string; page: number },
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getSimilarPlaylists', async ({ key, page }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/playlist/${key}/similar?page=${page}`)
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})
