import { LoadingStatus } from '@/consts/common'
import { CustomError } from '@/helpers/errors'
import { AlbumObject, SimilarObject, TracksObject } from '@/types/collection'
import { createSlice } from '@reduxjs/toolkit'
import { Collection } from '@aims-api/aims-node/dist/helpers/types/collection'
import { getAlbum, getSimilarAlbums, getTracksOfAlbum } from './thunks'

export interface AlbumState {
  all: Record<Collection['key'], AlbumObject>
  tracksMap: Record<Collection['key'], TracksObject>
  similarMap: Record<Collection['key'], SimilarObject>
  error: CustomError
}

const initialState: AlbumState = {
  all: {},
  tracksMap: {},
  similarMap: {},
  error: {
    status: null,
    message: '',
  },
}

export const albumSlice = createSlice({
  name: 'album',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAlbum.pending, (state, { meta }) => {
      const { key } = meta.arg
      state.error = initialState.error
      state.all = {
        ...state.all,
        [key]: {
          loadingStatus: LoadingStatus.LOADING,
        },
      }
    })
    builder.addCase(getAlbum.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      const collection = payload.collection
      state.all = {
        ...state.all,
        [collection.key]: {
          album: collection,
          loadingStatus: LoadingStatus.LOADED,
        },
      }
    })
    builder.addCase(getAlbum.rejected, (state, { payload, meta }) => {
      const { key } = meta.arg
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
      state.all = {
        ...state.all,
        [key]: {
          loadingStatus: LoadingStatus.ERROR,
        },
      }
    })

    builder.addCase(getTracksOfAlbum.pending, (state, { meta }) => {
      const { key } = meta.arg
      state.error = initialState.error
      state.tracksMap[key] = {
        tracks: [],
        loadingStatus: LoadingStatus.LOADING,
      }
    })
    builder.addCase(getTracksOfAlbum.fulfilled, (state, { payload, meta }) => {
      const { key } = meta.arg
      state.tracksMap[key] = {
        tracks: payload.tracks,
        loadingStatus: LoadingStatus.LOADED,
      }
    })
    builder.addCase(getTracksOfAlbum.rejected, (state, { payload, meta }) => {
      const { key } = meta.arg
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
      state.tracksMap[key] = {
        ...state.tracksMap[key],
        loadingStatus: LoadingStatus.ERROR,
      }
    })

    builder.addCase(getSimilarAlbums.pending, (state, { meta }) => {
      const { key } = meta.arg
      state.error = initialState.error
      state.similarMap[key] = {
        ...state.similarMap[key],
        collections: state.similarMap[key] !== undefined ? state.similarMap[key].collections : [],
        loadingStatus: LoadingStatus.LOADING,
      }
    })
    builder.addCase(getSimilarAlbums.fulfilled, (state, { payload, meta }) => {
      const { key, page } = meta.arg
      const oldSimilar = state.similarMap[key].collections
      const newSimilar = payload.collections
      state.similarMap[key] = {
        collections: [...oldSimilar, ...newSimilar],
        page,
        loadingStatus: LoadingStatus.LOADED,
      }
    })
    builder.addCase(getSimilarAlbums.rejected, (state, { payload, meta }) => {
      const { key } = meta.arg
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
      state.similarMap[key] = {
        ...state.similarMap[key],
        loadingStatus: LoadingStatus.ERROR,
      }
    })
  },
})

// export const {} = albumSlice.actions

export default albumSlice.reducer
