import { z } from 'zod'

const trackTagsSchema = z.record(z.array(z.string()).or(z.nullable(z.number())))

export const trackSchema = z.object({
  id: z.string(),
  title: z.string(),
  createdAt: z.string().datetime({ offset: true }),
  processedAt: z.nullable(z.string().datetime({ offset: true })),
  duration: z.nullable(z.number()),
  filesize: z.number(),
  queuedAt: z.nullable(z.string().datetime({ offset: true })),
  modelVersion: z.nullable(z.string()),
  batchId: z.string(),
  tags: z.nullable(trackTagsSchema),
  processingError: z.nullable(z.string()),
})

export const trackListSchema = z.array(trackSchema)

export type TrackTags = z.infer<typeof trackTagsSchema>
export type TrackList = z.infer<typeof trackListSchema>
export type Track = z.infer<typeof trackSchema> & { url?: string; idForPlayer?: string }

export const batchSchema = z.object({
  id: z.string(),
  name: z.string(),
  tracks: z.array(trackSchema),
  createdAt: z.nullable(z.string().datetime({ offset: true })),
  taggingStartedAt: z.nullable(z.string().datetime({ offset: true })),
  taggingCompletedAt: z.nullable(z.string().datetime({ offset: true })),
  lastExportedAt: z.nullable(z.string().datetime({ offset: true })),
  availablePresets: z.array(z.string()),
})

export const batchListSchema = z.array(batchSchema)

export type BatchList = z.infer<typeof batchListSchema>
export type Batch = z.infer<typeof batchSchema>
