import { PaletteOptions } from '@mui/material/styles/createPalette'
import { grey, primary } from './general'

export const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary,
  secondary: {
    light: '#211D25',
    main: grey[100],
    dark: '#0D0911',
  },
  success: {
    main: 'rgba(10, 190, 117, 1)',
  },
  error: {
    light: '#FEEEEE',
    main: '#F75555',
  },
  warning: {
    light: '#ff9800',
    main: '#ed6c02',
    dark: '#A35A0C',
  },
  info: {
    light: '#b3c7f0',
    main: '#0288d1',
  },
  background: {
    default: '#0D0911',
    // success: '#2F7D31',
    // error: '#BD0000',
  },
  text: {
    primary: '#FBF5FF',
    secondary: '#CBC6CF',
    disabled: grey[400], // 'rgba(251, 245, 255, 0.25)',
  },
  grey,
  action: {
    hover: '#3a3640',
    disabled: 'rgba(251, 245, 255, 0.10)',
  },
} as const
