import { createSlice } from '@reduxjs/toolkit'
import { Mapping } from '../../types/taxonomyMapping'
import { getCustomCategoryList, getMapping, updateMapping } from './thunks'
import { CustomCategory } from '../../types/categories'

export interface CustomTaxonomyState {
  mapping: Mapping
  isMappingEnabled: boolean
  isMappingLoading: boolean
  customCategories: CustomCategory[]
  isMappingUpdatePending: boolean
}

const initialState: CustomTaxonomyState = {
  mapping: null,
  isMappingEnabled: false,
  isMappingLoading: true,
  customCategories: [],
  isMappingUpdatePending: false,
}

export const customTaxonomySlice = createSlice({
  name: 'customTaxonomy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMapping.fulfilled, (state, { payload }) => {
      state.mapping = payload.mapping
      state.isMappingEnabled = payload.isMappingEnabled
      state.isMappingLoading = false
    })
    builder.addCase(getMapping.rejected, () => {
      return {
        ...initialState,
        isMappingLoading: false,
      }
    })
    builder.addCase(getCustomCategoryList.fulfilled, (state, { payload }) => {
      state.customCategories = payload
    })
    builder.addCase(updateMapping.pending, (state) => {
      state.isMappingUpdatePending = true
    })
    builder.addCase(updateMapping.fulfilled, (state) => {
      state.isMappingUpdatePending = false
    })
    builder.addCase(updateMapping.rejected, (state) => {
      state.isMappingUpdatePending = false
    })
  },
})

export default customTaxonomySlice.reducer
