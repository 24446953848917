import { createAsyncThunk } from '@reduxjs/toolkit'
import { CustomError, parseErrorFromThunk } from '../../helpers/errors'
import { axiosInstance } from '../../config/axios'
import { errors } from '../../consts/errors'
import { BatchList, batchListSchema, Batch, batchSchema, Track, trackSchema } from '../../types/batch'
import { NotificationStatus } from '../notification/slice'
import { RootState } from '../store'
import { subscriptionStatus } from '../auth/thunks'
import { selectIsMappingEnabled, selectMapping } from '../custom-taxonomy/selectors'
import { selectCurrentPreset } from '../api-user/selectors'
import { addNotification } from '../notification/thunks'

export const getBatchList = createAsyncThunk<
  BatchList,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
    state: RootState
  }
>('batch/getBatchList', async (_, thunkApi) => {
  try {
    const subscriptionStatusResponse = await thunkApi.dispatch(subscriptionStatus({})).unwrap()
    const isCustomTaxonomyEnabled = selectIsMappingEnabled()(thunkApi.getState())
    const customTaxonomyMapping = selectMapping()(thunkApi.getState())
    const currentPreset = selectCurrentPreset()(thunkApi.getState())

    const response = await axiosInstance.post('/api/batch/list', {
      preset: currentPreset?.id,
      mapping:
        isCustomTaxonomyEnabled && customTaxonomyMapping !== null
          ? customTaxonomyMapping.categories
          : currentPreset === undefined
            ? undefined
            : currentPreset.mapping?.categories,
      allTagCategories:
        subscriptionStatusResponse?.features?.allTagCategories !== undefined &&
        subscriptionStatusResponse.features.allTagCategories
          ? true
          : undefined,
    })
    const parserResponse = batchListSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const createBatch = createAsyncThunk<
  Batch,
  string,
  {
    rejectValue: CustomError
  }
>('batch/createBatch', async (name, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/batch/create', { name })
    const parserResponse = batchSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const renameBatch = createAsyncThunk<
  Batch,
  { batchId: string; name: string },
  {
    rejectValue: CustomError
    state: RootState
  }
>('batch/renameBatch', async ({ batchId, name }, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/batch/update', { batchId, name })
    const parserResponse = batchSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.SUCCESS,
        message: `Batch was renamed`,
      }),
    )
    return parserResponse.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const deleteBatch = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  string,
  {
    rejectValue: CustomError
  }
>('batch/deleteBatch', async (batchId, thunkApi) => {
  try {
    await axiosInstance.delete(`/api/batch/delete/${batchId}`)
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getBatchDetail = createAsyncThunk<
  Batch,
  { batchId: string; presetId?: string },
  {
    rejectValue: CustomError
    state: RootState
  }
>('batch/getBatchDetail', async ({ batchId, presetId }, thunkApi) => {
  try {
    const subscriptionStatusResponse = await thunkApi.dispatch(subscriptionStatus({})).unwrap()
    const isCustomTaxonomyEnabled = selectIsMappingEnabled()(thunkApi.getState())
    const customTaxonomyMapping = selectMapping()(thunkApi.getState())
    const currentPreset = selectCurrentPreset()(thunkApi.getState())

    const response = await axiosInstance.post(`/api/batch/detail`, {
      batchId,
      preset: currentPreset?.id,
      mapping:
        isCustomTaxonomyEnabled && customTaxonomyMapping !== null
          ? customTaxonomyMapping.categories
          : currentPreset === undefined
            ? undefined
            : currentPreset.mapping?.categories,
      allTagCategories:
        subscriptionStatusResponse?.features?.allTagCategories !== undefined &&
        subscriptionStatusResponse.features.allTagCategories,
    })
    const parserResponse = batchSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const startTagging = createAsyncThunk<
  Batch,
  { batchId: string; totalItemsCount: number; trackIds: string[] },
  {
    rejectValue: CustomError
  }
>('batch/startTagging', async ({ batchId }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`/api/batch/start-tagging`, { batchId })
    const parserResponse = batchSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const addTrack = createAsyncThunk<
  Track,
  FormData,
  {
    rejectValue: CustomError
  }
>('batch/addTrack', async (data, thunkApi) => {
  try {
    const response = await axiosInstance.post(`/api/batch/add-track`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    const parserResponse = trackSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const deleteTrack = createAsyncThunk<
  { batchId: string; track: Track },
  { batchId: string; trackId: string },
  {
    rejectValue: CustomError
  }
>('batch/deleteTrack', async ({ batchId, trackId }, thunkApi) => {
  try {
    const response = await axiosInstance.delete(`/api/track/delete/${trackId}`)
    const parserResponse = trackSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }
    return { batchId, track: parserResponse.data }
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const removeTag = createAsyncThunk<
  Track,
  {
    batchId: string
    trackId: string
    category: string
    value: string
    shouldNotify?: boolean
    updateStore?: boolean
  },
  {
    rejectValue: { error: CustomError; tag: string | null }
    state: RootState
  }
>('batch/track/removeTag', async ({ trackId, category, value, shouldNotify = true }, thunkApi) => {
  try {
    const currentPreset = selectCurrentPreset()(thunkApi.getState())

    const response = await axiosInstance.post(`/api/track/remove-tag`, {
      trackId,
      category,
      value,
      preset: currentPreset?.id,
    })
    const parserResponse = trackSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue({ error: errors.PARSER, tag: null })
    }

    if (shouldNotify)
      void thunkApi.dispatch(
        addNotification({
          variant: NotificationStatus.INFO,
          message: `Tag was removed`,
        }),
      )
    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    if (shouldNotify) {
      void thunkApi.dispatch(
        addNotification({
          variant: NotificationStatus.ERROR,
          message: errors.OPERATION_FAILED_RETRY.message,
        }),
      )
    }
    return thunkApi.rejectWithValue({ error: customError, tag: value })
  }
})

export const addTag = createAsyncThunk<
  Track,
  {
    batchId: string
    trackId: string
    category: string
    value: string
    replacedTag?: string
    shouldNotify?: boolean
    updateStore?: boolean
  },
  {
    rejectValue: { error: CustomError; tag: string | null }
    state: RootState
  }
>('batch/track/addTag', async ({ batchId, trackId, category, value, replacedTag, shouldNotify = true }, thunkApi) => {
  try {
    if (replacedTag !== undefined) {
      await thunkApi.dispatch(removeTag({ batchId, trackId, category, value: replacedTag, shouldNotify: false }))
    }

    const currentPreset = selectCurrentPreset()(thunkApi.getState())

    const response = await axiosInstance.post(`/api/track/add-tag`, {
      trackId,
      category,
      value,
      preset: currentPreset?.id,
    })
    const parserResponse = trackSchema.safeParse(response.data.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue({ error: errors.PARSER, tag: null })
    }

    if (shouldNotify) {
      void thunkApi.dispatch(
        addNotification({
          variant: NotificationStatus.SUCCESS,
          message: replacedTag === undefined ? `Tag was added` : `Tag was replaced`,
        }),
      )
    }

    return parserResponse.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    if (shouldNotify) {
      void thunkApi.dispatch(
        addNotification({
          variant: NotificationStatus.ERROR,
          message: errors.OPERATION_FAILED_RETRY.message,
        }),
      )
    }
    return thunkApi.rejectWithValue({ error: customError, tag: value })
  }
})
