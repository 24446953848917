import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SnackbarKey } from 'notistack'
import { v4 as uuidv4 } from 'uuid'
import { addNotification } from './thunks'

export interface Notification {
  key: SnackbarKey
  variant: Status
  message: string
  autoHideDuration?: null
  dismissed?: boolean
  isPublic?: boolean
}

export type NewNotification = Omit<Notification, 'key' | 'dismissed'>

export const initialState: Notification[] = []

export const NotificationStatus = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
} as const

type Status = (typeof NotificationStatus)[keyof typeof NotificationStatus]

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    dismissNotification: (state, action: PayloadAction<{ key: SnackbarKey; dismissAll?: boolean }>) =>
      state.map((notification) =>
        (action.payload.dismissAll ?? notification.key === action.payload.key)
          ? { ...notification, dismissed: true }
          : notification,
      ),
    removeNotification: (state, action: PayloadAction<SnackbarKey>) =>
      state.filter((notification) => notification.key !== action.payload),
  },
  extraReducers: (builder) => {
    builder.addCase(addNotification.fulfilled, (state, { payload }) => {
      void state.push({
        ...payload,
        key: uuidv4(),
        autoHideDuration: payload.variant === NotificationStatus.ERROR ? null : undefined,
      })
    })
  },
})

export const { dismissNotification, removeNotification } = notificationSlice.actions

export default notificationSlice.reducer
