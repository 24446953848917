import { selectSiteFeatures } from '@/store/auth/selectors'
import { useAppSelector } from '@/store/hooks'
import { Box, Container, ContainerTypeMap, Theme, Typography } from '@mui/material'
import Head from 'next/head'
import Image from 'next/image'
import { PropsWithChildren } from 'react'
import LegacyBrowserAlert from '../LegacyBrowserAlert'
import Logo from '../Logo'
import styles from './AuthLogin.module.css'

const getStyles = (theme: Theme) =>
  ({
    container: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        width: '50%',
      },
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      minHeight: '100%',
    },
    backgroundBox: {
      position: 'relative',
      bottom: 54,
      maxWidth: '90%',
      '& h1': {
        textAlign: 'center',
        marginBottom: '30px',
        fontSize: 30,
        textWrap: 'pretty',
        [theme.breakpoints.down('md')]: {
          color: 'white',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '40px',
          textWrap: 'unset',
        },
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '70%',
        position: 'static',
      },
    },
    footer: {
      mt: 5.5,
      textAlign: 'center',
    },
    text: {
      color: 'white',
      fontSize: '12px',
      fontWeight: '500px',
    },
  }) as const

const AuthLayout = ({
  children,
  containerWidth = false,
  footer,
  title,
}: PropsWithChildren & {
  containerWidth?: ContainerTypeMap['props']['maxWidth']
  footer?: JSX.Element
  title?: string
}) => {
  const siteFeatures = useAppSelector(selectSiteFeatures)

  return (
    <div className={styles.wrapper}>
      <Head>
        <title>{title === undefined ? 'AIMS Auto-Tagging' : `${title} - AIMS Auto-Tagging`}</title>
      </Head>
      <LegacyBrowserAlert />
      <div className={styles.content}>
        <Box sx={(theme) => getStyles(theme).container}>
          <section className={styles.mobileIntro}>
            <div className={styles.shadow}></div>
            <div style={{ margin: '0 auto' }}>
              <Logo
                src={siteFeatures?.platformConfig?.branding?.header?.logo}
                color="white"
                withLabel={true}
                size={92}
              />
            </div>
          </section>
          <Container sx={(theme) => getStyles(theme).backgroundBox} maxWidth={containerWidth} disableGutters>
            {children}
          </Container>
          {footer !== undefined && <Box sx={(theme) => getStyles(theme).footer}>{footer}</Box>}
        </Box>
        <div className={styles.rightSide}>
          <div className={styles.bottom}>
            <div className={styles.bottomTextWrapper}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Logo src={siteFeatures?.platformConfig?.branding?.header?.logo} color="white" />
              </div>
              <Image src={'/divider.svg'} alt="Divider" height={31} width={24} priority />
              <Typography sx={(theme) => getStyles(theme).text}>
                {'We Help People Uncover the Perfect Music For Their Art, Work, and Experiences'}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
