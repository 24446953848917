import { createSlice, current, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { TaxonomyPreset } from '../../types/taxonomyPreset'
import { getUserPresets } from './thunks'
import { AIMS_STANDARD_PRESET } from '../../consts/taxonomyMapping'
export interface ApiUserState {
  darkMode?: boolean
  userPresets: TaxonomyPreset[]
  currentPresetId?: string
}

export const initialState: ApiUserState = {
  userPresets: [AIMS_STANDARD_PRESET],
}

// TODO: think about better naming for this slice
export const apiUserSlice = createSlice({
  name: 'api-user',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      const currentState = current(state).darkMode ?? false
      state.darkMode = !currentState
    },
    setCurrentPresetId: (state, action: PayloadAction<string | undefined>) => {
      state.currentPresetId = action.payload
      if (action.payload === undefined) {
        localStorage.removeItem('presetId')
        return
      }
      localStorage.setItem('presetId', action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPresets.fulfilled, (state, { payload }) => {
      state.userPresets = [...initialState.userPresets, ...payload]
    })
  },
})

export const asyncToggleTheme = (isDarkModeEnabled: boolean) => (dispatch: Dispatch) => {
  // const isDarkMode = !!JSON.parse(localStorage.getItem('darkMode') ?? 'false')
  localStorage.setItem('darkMode', (!isDarkModeEnabled).toString())
  dispatch(toggleTheme())
}

export const { toggleTheme, setCurrentPresetId } = apiUserSlice.actions

export default apiUserSlice.reducer
