import { createAsyncThunk } from '@reduxjs/toolkit'
import { selectUserId } from '../auth/selectors'
import { NewNotification } from './slice'
import { RootState } from '../store'

export const addNotification = createAsyncThunk<
  NewNotification,
  NewNotification,
  {
    rejectValue: ''
    state: RootState
  }
>('notification/add', (notification, thunkApi) => {
  const userId = selectUserId(thunkApi.getState())
  if (notification.isPublic === true || userId !== '') {
    return notification
  }
  return thunkApi.rejectWithValue('')
})
