import { Box, Typography } from '@mui/material'
import { isLegacyBrowser } from '../../helpers/browserCompatibility'
import { useState } from 'react'

const styles = {
  container: {
    bgcolor: 'error.light',
    color: 'error.main',
    minHeight: 48,
    display: 'flex',
    justifyContent: 'space-between',
    pl: 8,
    pr: 6,
  },
  content: {
    pt: 1.8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  closeIcon: {
    fontSize: 40.5,
    lineHeight: 1.2,
    cursor: 'pointer',
  },
}

const LegacyBrowserAlert = () => {
  const [show, setShow] = useState(true)

  return show && isLegacyBrowser() ? (
    <Box sx={styles.container}>
      <Typography sx={styles.content}>
        Version of the browser you’re using is not supported. Update your browser, please.
      </Typography>
      <Typography sx={styles.closeIcon} onClick={() => setShow(false)}>
        &times;
      </Typography>
    </Box>
  ) : null
}

export default LegacyBrowserAlert
