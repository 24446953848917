import { useRouter } from 'next/router'
import { useEffect } from 'react'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hsConversationsOnReady: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hsConversationsSettings: any
  }
}

const useHubSpotChat = () => {
  const router = useRouter()
  useEffect(() => {
    if (['/share/[snapshotId]', '/magic-login/[hash]'].includes(router.pathname)) {
      return
    }
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `//js-eu1.hs-scripts.com/25438194.js`
    script.async = true
    document.body.appendChild(script)

    window.hsConversationsSettings = {
      loadImmediately: false,
    }
    // case: open when loaded (initiated in components/Layout/Menu)
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.open()
      },
    ]
    window.HubSpotConversations?.on('widgetClosed', () => {
      window.HubSpotConversations.widget.remove()
    })

    return () => {
      document.body.removeChild(script)
    }
  }, [router])
}

export default useHubSpotChat
