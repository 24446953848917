import { createSlice } from '@reduxjs/toolkit'
import { DefaultCategory } from '../../types/categories'
import { getCategories } from './thunks'

export interface CategoriesState {
  categories: DefaultCategory[]
  isLoading: boolean
}

const initialState: CategoriesState = {
  categories: [],
  isLoading: true,
}

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, { payload }) => {
      state.categories = payload.sort((a, b) => a.priority - b.priority)
      state.isLoading = false
    })
    builder.addCase(getCategories.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export default categoriesSlice.reducer
