import { LoadingStatus } from '@/consts/common'
import { CustomError } from '@/helpers/errors'
import { PlaylistObject, SimilarObject, SuggestedTracksObject, TracksObject } from '@/types/collection'
import { createSlice } from '@reduxjs/toolkit'
import { Collection } from '@aims-api/aims-node/dist/helpers/types/collection'
import { getPlaylist, getSimilarPlaylists, getSuggestedTracksForPlaylist, getTracksOfPlaylist } from './thunks'

export interface PlaylistState {
  all: Record<Collection['key'], PlaylistObject>
  tracksMap: Record<Collection['key'], TracksObject>
  suggestedTracksMap: Record<Collection['key'], SuggestedTracksObject>
  similarMap: Record<Collection['key'], SimilarObject>
  error: CustomError
}

const initialState: PlaylistState = {
  all: {},
  tracksMap: {},
  suggestedTracksMap: {},
  similarMap: {},
  error: {
    status: null,
    message: '',
  },
}

export const playlistSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPlaylist.pending, (state, { meta }) => {
      const { key } = meta.arg
      state.error = initialState.error
      state.all = {
        ...state.all,
        [key]: {
          loadingStatus: LoadingStatus.LOADING,
        },
      }
    })
    builder.addCase(getPlaylist.fulfilled, (state, { payload }) => {
      state.error = initialState.error
      const collection = payload.collection
      state.all = {
        ...state.all,
        [collection.key]: {
          playlist: collection,
          loadingStatus: LoadingStatus.LOADED,
        },
      }
    })
    builder.addCase(getPlaylist.rejected, (state, { payload, meta }) => {
      const { key } = meta.arg
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
      state.all = {
        ...state.all,
        [key]: {
          loadingStatus: LoadingStatus.ERROR,
        },
      }
    })

    builder.addCase(getTracksOfPlaylist.pending, (state, { meta }) => {
      const { key } = meta.arg
      state.error = initialState.error
      state.tracksMap[key] = {
        tracks: [],
        loadingStatus: LoadingStatus.LOADING,
      }
    })
    builder.addCase(getTracksOfPlaylist.fulfilled, (state, { payload, meta }) => {
      const { key } = meta.arg
      state.tracksMap[key] = {
        tracks: payload.tracks,
        loadingStatus: LoadingStatus.LOADED,
      }
    })
    builder.addCase(getTracksOfPlaylist.rejected, (state, { payload, meta }) => {
      const { key } = meta.arg
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
      state.tracksMap[key] = {
        ...state.tracksMap[key],
        loadingStatus: LoadingStatus.ERROR,
      }
    })

    builder.addCase(getSimilarPlaylists.pending, (state, { meta }) => {
      const { key } = meta.arg
      state.error = initialState.error
      state.similarMap[key] = {
        ...state.similarMap[key],
        collections: state.similarMap[key] !== undefined ? state.similarMap[key].collections : [],
        loadingStatus: LoadingStatus.LOADING,
      }
    })
    builder.addCase(getSimilarPlaylists.fulfilled, (state, { payload, meta }) => {
      const { key, page } = meta.arg
      const oldSimilar = state.similarMap[key].collections
      const newSimilar = payload.collections
      state.similarMap[key] = {
        collections: [...oldSimilar, ...newSimilar],
        page,
        loadingStatus: LoadingStatus.LOADED,
      }
    })
    builder.addCase(getSimilarPlaylists.rejected, (state, { payload, meta }) => {
      const { key } = meta.arg
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
      state.similarMap[key] = {
        ...state.similarMap[key],
        loadingStatus: LoadingStatus.ERROR,
      }
    })

    builder.addCase(getSuggestedTracksForPlaylist.pending, (state, { meta }) => {
      const { key, page } = meta.arg
      state.error = initialState.error
      state.suggestedTracksMap[key] = {
        tracks: [],
        loadingStatus: LoadingStatus.LOADING,
        page,
      }
    })
    builder.addCase(getSuggestedTracksForPlaylist.fulfilled, (state, { payload, meta }) => {
      const { key, page } = meta.arg
      state.suggestedTracksMap[key] = {
        ...state.suggestedTracksMap[key],
        tracks: payload.tracks,
        loadingStatus: LoadingStatus.LOADED,
        page,
      }
    })
    builder.addCase(getSuggestedTracksForPlaylist.rejected, (state, { payload, meta }) => {
      const playlistKey = meta.arg.key
      if (payload !== undefined) {
        state.error.status = payload.status
        state.error.message = payload.message
      }
      state.suggestedTracksMap[playlistKey] = {
        ...state.suggestedTracksMap[playlistKey],
        loadingStatus: LoadingStatus.ERROR,
      }
    })
  },
})

// export const {} = playlistSlice.actions

export default playlistSlice.reducer
