import Link from 'next/link'
import { Box, Theme, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { SUPPORT_MAIL } from '../../consts/common'
import { selectUserId } from '../../store/auth/selectors'
import { initialState } from '../../store/auth/slice'
import { useAppSelector } from '../../store/hooks'
import Button from '../Button'
import { grey, primary } from '@/styles/palettes/general'

// TODO: refactor
const getCheckIconStyles = (theme: Theme) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    margin: '0 auto',
  },
  display: 'none',
  fontSize: 78,
  mb: 2,
})

const styles = {
  wrapper: {
    textAlign: 'center',
    '& a': {
      textDecoration: 'none',
    },
  },
  secondaryTextLink: {
    color: grey[700],
    lineHeight: 1.5,
    mt: 2,
    '& a': {
      textDecoration: 'underline',
      color: primary.main,
    },
  },
  button: {
    display: 'flex',
    mx: 'auto',
    mt: 4.5,
    mb: 2,
  },
}

export enum State {
  Success,
  Error,
}

type ResultTemplateProps =
  | {
      state: State.Error
      mainText?: string
      secondaryText?: string | JSX.Element
      icon?: JSX.Element
      actionButtonText?: string
    }
  | {
      state: State.Success
      mainText: string
      secondaryText: string | JSX.Element
      icon?: JSX.Element
      actionButtonText?: string
    }

const ResultTemplate = ({ state, mainText, secondaryText, icon, actionButtonText }: ResultTemplateProps) => {
  const isError = state === State.Error
  const userId = useAppSelector(selectUserId)
  const isLoggedIn = userId !== undefined && userId !== initialState.userData.id

  const link = isError && isLoggedIn ? '/dashboard' : '/login'
  const iconBasedOnState = isError ? (
    <WarningAmberIcon sx={(theme) => getCheckIconStyles(theme)} color="error" />
  ) : (
    <CheckCircleOutlineIcon sx={(theme) => getCheckIconStyles(theme)} color="success" />
  )

  const handleErrorRedirect = () => {
    window.location.replace(link)
  }

  return (
    <Box sx={styles.wrapper}>
      {icon !== undefined
        ? // TODO: jest warning from ResultTemplate/index.test.tsx
          { ...icon, props: { ...icon.props, sx: (theme: Theme) => getCheckIconStyles(theme) } }
        : iconBasedOnState}
      <Typography variant="h1">
        {mainText !== undefined ? mainText : 'Sorry, there was an unexpected error.'}
      </Typography>
      <Typography
        variant="h4"
        sx={(theme) => ({
          ...styles.secondaryTextLink,
          color: theme.palette.mode === 'light' ? grey[700] : theme.palette.grey[200],
        })}
        component="div"
      >
        {secondaryText !== undefined ? (
          secondaryText
        ) : (
          <span>
            {'Please try again later or '}
            <a href={`mailto:${SUPPORT_MAIL}`}>contact us</a>
            {' if the issue remains.'}
          </span>
        )}
      </Typography>
      {isError && isLoggedIn ? (
        <Button sx={styles.button} variant="contained" onClick={handleErrorRedirect}>
          {actionButtonText ?? 'Go back to homepage'}
        </Button>
      ) : (
        <Link href={link}>
          <Button sx={styles.button} variant="contained">
            {'Go to Login'}
          </Button>
        </Link>
      )}
    </Box>
  )
}

export default ResultTemplate
