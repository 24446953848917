import { TaxonomyPreset } from '../types/taxonomyPreset'

export const AIMS_STANDARD_PRESET: TaxonomyPreset = {
  id: 'AIMS_STANDARD_PRESET',
  name: 'AIMS standard (EN)',
  premium: false,
  mapping: null,
} as const

export const tabs = {
  CATEGORIES: {
    label: 'Categories',
    disabled: false,
    slug: 'Categories',
    tooltipForDisabled: 'Categories',
  },
  AI_TAGS_MAPPING: {
    label: 'AI tags mapping',
    disabled: false,
    slug: 'AI tags mapping',
    tooltipForDisabled: 'AI tags mapping',
  },
  CUSTOM_TAGS: {
    label: 'Custom tags',
    disabled: false,
    slug: 'Custom tags',
    tooltipForDisabled: 'Custom tags',
  },
  SPECIAL_RULES: {
    label: 'Special rules',
    disabled: false,
    slug: 'Special rules',
    tooltipForDisabled: 'Special rules',
  },
} as const

export type TabOpts = (typeof tabs)[keyof typeof tabs]['slug']
