import { BatchState } from './slice'
import { Batch, Track } from '../../types/batch'
import { getTrackStatus, TrackStatus } from '../../helpers/autoTagging/TrackStatus'

const updateTrack = (oldTrack: Track, newTrack: Track): Track => {
  let duration = oldTrack.duration

  // If new duration comes from api, update it
  if (newTrack.duration !== null) {
    duration = newTrack.duration
  }

  // Update the track with according duration, keep all the old data that
  // are not contained in api response
  return { ...oldTrack, ...newTrack, duration }
}

export const updateBatch = (state: BatchState, batchNew: Batch): BatchState => {
  const batchIndex = state.list.findIndex((batch) => batch.id === batchNew.id)

  // If no batch found, push the batch
  if (batchIndex === -1) {
    state.list.push(batchNew)
    return state
  }

  // If batch found

  // Update everything but keep old tracks
  const oldTracks = state.list[batchIndex].tracks
  state.list[batchIndex] = { ...batchNew, tracks: oldTracks }

  const errorTracks: Track[] = []
  // Go through every track that came from api
  batchNew.tracks.forEach((track) => {
    const trackIndex = state.list[batchIndex].tracks.findIndex((t) => t.id === track.id)

    // If track is not found in batch, then add it to batch
    if (trackIndex === -1) {
      state.list[batchIndex].tracks.push(track)
      return
    }

    // If track has error status
    if (track.processingError !== null) {
      errorTracks.push(track)
      state.list[batchIndex].tracks.splice(trackIndex, 1)
      return
    }

    // If track is found in batch

    const oldTrack = state.list[batchIndex].tracks[trackIndex]
    state.list[batchIndex].tracks[trackIndex] = updateTrack(oldTrack, track)
  })

  state.list[batchIndex].tracks.unshift(...errorTracks)

  return state
}

export const sortTracks = (trackList: Track[]): Track[] => {
  return trackList.sort((trackFirst, trackSecond) => {
    const statusFirst = getTrackStatus(trackFirst)
    const statusSecond = getTrackStatus(trackSecond)
    const priorities = {
      [TrackStatus.UNKNOWN]: 3,
      [TrackStatus.COMPLETED]: 2,
      [TrackStatus.WAITING_FOR_TAGGING]: 2,
      [TrackStatus.READY_TO_TAG]: 1,
      [TrackStatus.UPLOADING]: 1,
      [TrackStatus.WAITING_FOR_UPLOAD]: 1,
      [TrackStatus.ERROR]: 0,
    }
    const statusDifference = priorities[statusFirst] - priorities[statusSecond]
    return statusDifference !== 0
      ? statusDifference
      : new Date(trackSecond.createdAt).getTime() - new Date(trackFirst.createdAt).getTime()
  })
}
