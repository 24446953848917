import { LoadingStatus } from '@/consts/common'
import { RootState } from '../store'
import { createSelector } from '@reduxjs/toolkit'
import { getTracksAsNested } from '@/helpers/searchSection/getTracksAsNested'

export const selectResultsLoadingStatus = (state: RootState) => state.discovery.resultsLoadingStatus

export const selectIsResultsLoading = (state: RootState) =>
  state.discovery.resultsLoadingStatus === LoadingStatus.LOADING

export const selectHasAnyResults = (state: RootState) => state.discovery.results.length > 0

export const selectActiveSearch = (state: RootState) => state.discovery.activeSearch

export const selectLastHash = (state: RootState) => selectActiveSearch(state)?.hash

export const selectChipLoadingStatus = (state: RootState) => state.discovery.chipLoadingStatus

export const selectIsChipLoading = (state: RootState) => state.discovery.chipLoadingStatus === LoadingStatus.LOADING

export const selectPrevSearch = (state: RootState) =>
  state.discovery.recentSearches.length > 1 ? state.discovery.recentSearches[1] : undefined

export const selectRecentSearches = (state: RootState) => state.discovery.recentSearches

export const selectResults = (state: RootState) => state.discovery.results

export const selectPage = (state: RootState) => state.discovery.page

export const selectHasMoreResults = (state: RootState) => state.discovery.hasMoreResults

export const selectSearchParams = (state: RootState) => state.discovery.searchParams

export const selectSegmentRange = (state: RootState) => state.discovery.segmentRange

export const selectUserInteractedWithSegment = (state: RootState) =>
  state.discovery.hasUserInteractedWithSegment ?? false

export const selectAppliedSearchParams = (state: RootState) => state.discovery.appliedSearchParams

export const selectSegmentDuration = (state: RootState) => state.discovery.segmentContent.duration

export const selectSegmentTrack = (state: RootState) => state.discovery.segmentContent.track

export const selectIsEmbeddedPlayerReady = (state: RootState) => state.discovery.segmentContent.embeddedPlayerReady

export const selectThumbnails = (state: RootState) => state.discovery.thumbnails

export const selectFilterFields = (state: RootState) => state.discovery.filterFields

export const selectNestedResults = createSelector([selectResults, selectPage], (results) => getTracksAsNested(results))

export const selectFiltersForAPI = (state: RootState) => state.discovery.filtersForAPI

export const selectFiltersFormShouldReset = (state: RootState) => state.discovery.filtersFormShouldReset

export const selectAutocompleteResults = (query: string) => (state: RootState) =>
  state.discovery.autocompleteResults[query]

export const selectPromptSuggestionsResults = (query: string) => (state: RootState) =>
  state.discovery.promptSuggestionsResults[query]

export const selectFiltersOpen = (state: RootState) => state.discovery.filtersOpen

export const selectTrackInfo = (state: RootState) => state.discovery.trackInfo

export const selectTrackInfoOpen = (state: RootState) => state.discovery.trackInfoOpen

export const selectRightSidebarOpen = (state: RootState) => state.discovery.trackInfoOpen || state.discovery.filtersOpen

export const selectIsSearchParamsChanged = (state: RootState) => {
  const searchParams = selectSearchParams(state)
  const appliedSearchParams = selectAppliedSearchParams(state)
  const segmentRange = selectSegmentRange(state)

  let queryParamsChanged
  let segmentRangeChanged

  if (appliedSearchParams === null) {
    // compare actual controls state with default values
    queryParamsChanged = searchParams.prioritise_bpm || searchParams.suppress_vocals
    segmentRangeChanged = segmentRange.from !== 30 || segmentRange.to !== 90
  } else {
    // compare actual controls state with previous applied search params
    queryParamsChanged =
      searchParams.prioritise_bpm !== appliedSearchParams.queryParams.prioritise_bpm ||
      searchParams.suppress_vocals !== appliedSearchParams.queryParams.suppress_vocals
    segmentRangeChanged =
      segmentRange.from !== appliedSearchParams.segmentRange.from ||
      segmentRange.to !== appliedSearchParams.segmentRange.to
  }

  return queryParamsChanged || segmentRangeChanged
}
