import ErrorBoundary from '@/components/ErrorBoundary'
import SnackbarCloseButton from '@/components/Snackbar/SnackbarCloseButton'
import { hasPublicRouteInPathname } from '@/config/routes'
import { SiteFeatures, setSiteFeatures } from '@/store/auth/slice'
import { subscriptionStatus } from '@/store/auth/thunks'
import { store } from '@/store/store'
import { CacheProvider, EmotionCache } from '@emotion/react'
import ErrorIcon from '@mui/icons-material/Error'
import { ConfirmProvider } from 'material-ui-confirm'
import App, { AppContext, AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { SnackbarKey, SnackbarProvider } from 'notistack'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import RouteGuard from '../components/RouteGuard'
import ThemeProvider from '../components/ThemeProvider'
import setMUILicence from '../helpers/muiLicence'
import useHubSpotChat from '../helpers/useHubSpotChat'
import createEmotionCache from '../styles/createEmotionCache'

setMUILicence()
const Player = dynamic(async () => await import('@/components/Player'), {
  ssr: false,
})
const Analytics = dynamic(async () => await import('@/components/Analytics'), {
  ssr: false,
})
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

const styles = {
  errorIcon: {
    fontSize: 18,
    mr: 1,
  },
}

const confirmProviderDefaultOptions = {
  confirmationButtonProps: { autoFocus: true },
}

const snackbarAnchorOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
} as const

const snackbarIconVariant = {
  error: <ErrorIcon sx={styles.errorIcon} />,
}

const snackbarAction = (key: SnackbarKey) => <SnackbarCloseButton snackbarKey={key} />

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  siteFeatures: SiteFeatures
}

export default function MyCustomApp(props: MyAppProps): JSX.Element {
  const { Component, emotionCache = clientSideEmotionCache, pageProps, router, siteFeatures } = props
  useHubSpotChat()

  useEffect(() => {
    if (Object.keys(siteFeatures).length !== 0) {
      // case: writes only once
      store.dispatch(setSiteFeatures(siteFeatures))
    }
  }, [siteFeatures])

  useEffect(() => {
    if (!hasPublicRouteInPathname(router.pathname)) {
      void store.dispatch(
        subscriptionStatus({
          redirect: (path?: string) => {
            void router.push(path ?? '/dashboard')
          },
        }),
      )
    }
  }, [router])

  const firstInPath = router.pathname.split('/')[1]
  const isPlayerRequired = ['dashboard', 'batch', 'search', 'share', 'album', 'playlist'].includes(firstInPath)

  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider>
          <Analytics />
          <ConfirmProvider defaultOptions={confirmProviderDefaultOptions}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={snackbarAnchorOrigin}
              iconVariant={snackbarIconVariant}
              action={snackbarAction}
            >
              <ErrorBoundary>
                <RouteGuard>
                  <>
                    <Component {...pageProps} />
                  </>
                </RouteGuard>
                {isPlayerRequired ? <Player /> : null}
              </ErrorBoundary>
            </SnackbarProvider>
          </ConfirmProvider>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  )
}

MyCustomApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context)
  let siteFeatures = {}
  if (context.ctx.req !== undefined) {
    // server-side code
    const host = context.ctx.req?.headers.host
    if (host !== undefined) {
      const { extractSiteFeatures } = await import('@/helpers/server/api')
      siteFeatures = await extractSiteFeatures(host)
    }
  }
  return { ...ctx, siteFeatures }
}
