import { CustomError } from '@/helpers/errors'
import { Track } from '@/types/batch'
import { ExternalTrack, LinkSources } from '@/types/discovery'
import { DetailedTrack } from '@/types/discovery/track'
import { Project } from '@/types/project'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface PlayerState {
  audioElementId: string | null
  track: Track | DetailedTrack | ExternalTrack | null
  playingTrackId: Track['id'] | number | null
  waveformUrl: string | null
  error: CustomError
  toggledMasterTrackIds: string[]
  playingProjectId?: string
}

const initialState: PlayerState = {
  audioElementId: null,
  track: null,
  playingTrackId: null,
  waveformUrl: null,
  toggledMasterTrackIds: [],
  error: {
    status: null,
    message: '',
  },
}

type PlayTrackPayload =
  | Track
  | {
      track: DetailedTrack | ExternalTrack
      audioElementId: PlayerState['audioElementId']
      waveformUrl: PlayerState['waveformUrl']
      lastPlayTime?: number
      projectId?: Project['id']
    }

export interface LoadTrackPayload {
  track: Track | DetailedTrack | ExternalTrack
  audioElementId: PlayerState['audioElementId']
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    toggleMasterTrack: (state, action: PayloadAction<string>) => {
      if (state.toggledMasterTrackIds.some((id) => id === action.payload)) {
        state.toggledMasterTrackIds = state.toggledMasterTrackIds.filter((id) => id !== action.payload)
        return
      }
      state.toggledMasterTrackIds.push(action.payload)
    },
    loadTrack: (state, action: PayloadAction<LoadTrackPayload>) => {
      state.audioElementId = action.payload.audioElementId
      state.error = initialState.error
      const track = action.payload.track
      state.track = track
      // Reset waveformUrl when track is non-video external track meaning waveform should be computed directly from audio
      if (
        'type' in track &&
        track.type !== undefined &&
        ![LinkSources.YOUTUBE, LinkSources.VIMEO].includes(track.type)
      ) {
        state.waveformUrl = null
      }
    },
    playTrack: (state, action: PayloadAction<PlayTrackPayload>) => {
      state.error = initialState.error

      if (!('track' in action.payload)) {
        // Announcement: auto-tagging section
        state.playingTrackId = action.payload.id
        state.track = action.payload
        state.audioElementId = null
        state.waveformUrl = null
        return
      }

      state.playingProjectId = action.payload?.projectId ?? undefined
      state.playingTrackId = action.payload.audioElementId
      state.waveformUrl = action.payload.waveformUrl
      state.audioElementId = action.payload.audioElementId
      state.track = action.payload.track
    },
    pauseTrack: (state, action: PayloadAction<number | undefined>) => {
      const trackInPlayer = state.track
      if (trackInPlayer === null) {
        return
      }

      state.error = initialState.error
      state.playingTrackId = null
    },
    closePlayer: () => initialState,
    setPlayingTrackId: (state, action: PayloadAction<PlayerState['playingTrackId']>) => {
      state.playingTrackId = action.payload
    },
    setWaveformUrl: (state, action: PayloadAction<string>) => {
      state.waveformUrl = action.payload
    },
    setError: (state, action: PayloadAction<{ status: number; message: string }>) => {
      state.error.status = action.payload.status
      state.error.message = action.payload.message
    },
  },
})

export const {
  toggleMasterTrack,
  loadTrack,
  playTrack,
  pauseTrack,
  closePlayer,
  setPlayingTrackId,
  setWaveformUrl,
  setError,
} = playerSlice.actions

export default playerSlice.reducer
