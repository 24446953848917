import { z } from 'zod'
import { mappingSchema } from './taxonomyMapping'

const taxonomyPresetSchema = z.object({
  id: z.string(),
  name: z.string(),
  premium: z.boolean(),
  mapping: mappingSchema,
})

export type TaxonomyPreset = z.infer<typeof taxonomyPresetSchema>
export const taxonomyPresetListSchema = z.array(taxonomyPresetSchema)
