import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import { getDataFromLocalStorage } from '../helpers/store'
import authReducer, { initialState as authInitialState } from './auth/slice'
import albumReducer from './album/slice'
import batchReducer from './batch/slice'
import projectReducer from './project/slice'
import playerReducer from './player/slice'
import playlistReducer from './playlist/slice'
import notificationReducer from './notification/slice'
import categoriesReducer from './categories/slice'
import customTaxonomyReducer from './custom-taxonomy/slice'
import apiUserReducer, { initialState as apiUserInitialState } from './api-user/slice'
import { schemaApiUserCredentials } from '../types/auth'
import { z } from 'zod'
import discoveryReducer, { initialState as discoveryInitialState } from './discovery/slice'
import trackReducer from './track/slice'
import { schemaRecentSearches } from '../types/discovery'

const appReducer = combineReducers({
  auth: authReducer,
  album: albumReducer,
  batch: batchReducer,
  project: projectReducer,
  player: playerReducer,
  playlist: playlistReducer,
  notification: notificationReducer,
  categories: categoriesReducer,
  customTaxonomy: customTaxonomyReducer,
  apiUser: apiUserReducer,
  discovery: discoveryReducer,
  track: trackReducer,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === 'auth/logout/pending') {
    // Announcement: resets the state of the store, documented here: https://stackoverflow.com/a/35641992/6679040
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export const store = configureStore({
  reducer: reducerProxy,
  preloadedState: {
    apiUser: {
      ...apiUserInitialState,
      darkMode: getDataFromLocalStorage('darkMode', z.boolean()),
      currentPresetId: getDataFromLocalStorage('presetId', z.string()) ?? apiUserInitialState.currentPresetId,
    },
    auth: {
      ...authInitialState,
      userData: {
        ...authInitialState.userData,
        ...(getDataFromLocalStorage('userData', schemaApiUserCredentials) ?? {}),
      },
    },
    discovery: {
      ...discoveryInitialState,
      recentSearches: [
        ...(getDataFromLocalStorage(
          `discovery_${getDataFromLocalStorage('userData', schemaApiUserCredentials)?.id}`,
          schemaRecentSearches,
        ) ?? []),
      ],
    },
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
