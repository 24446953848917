import Stripe from 'stripe'

export interface FreeTrial {
  expired: boolean
  trialExpiresAt: undefined | string | null
  active: boolean
}

export const SubscriptionStatus = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
} as const

export type SubscriptionStatusType = (typeof SubscriptionStatus)[keyof typeof SubscriptionStatus] | null

export type PaymentInterval = 'month' | 'year'

export interface Price {
  id: string
  currency: string
  price: number | null
  billingScheme: Stripe.Price.BillingScheme
  itemsInPackage?: number
}

export interface Product {
  id: string
  name: string
  prices: { [key: string]: Price }
  metadata: Stripe.Metadata
}

export interface Products {
  Starter: Product
  Standard: Product
}

export interface Feature {
  title: string
  features: Array<{
    name: string
    Starter: boolean | string
    Standard: boolean | string
  }>
}

export interface Plans {
  products: Products | null
  features: Feature[]
}

export interface CreditProductPrice {
  id: string
  billingScheme: Stripe.Price.BillingScheme
  currency: string
  price: number | null
  itemsInPackage: number
}

export interface CreditProduct {
  id: string
  name: string
  prices: {
    [key: string]: CreditProductPrice
  }
}
