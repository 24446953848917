import { axiosInstance } from '@/config/axios'
import { errors } from '@/consts/errors'
import { SimilaritySearchAPIError, parseSimilaritySeachApiError } from '@/helpers/errors'
import { DetailedTrack } from '@/types/discovery/track'
import { Project } from '@/types/project'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CreateSnapshotRequest,
  CreateSnapshotResponse,
} from '@aims-api/aims-node/dist/endpoints/collections/snapshot/create'
import { CollectionsListDetailed, Snapshot } from '@aims-api/aims-node/dist/helpers/types/collection'
import { z } from 'zod'
import { selectStreamingHash, selectUserId } from '../auth/selectors'
import { NotificationStatus } from '../notification/slice'
import { addNotification } from '../notification/thunks'
import { selectTrackInPlayer } from '../player/selectors'
import { closePlayer } from '../player/slice'
import { RootState } from '../store'
import { selectIsTrackInProject, selectProjectById, selectTempTrackToBeAdded } from './selectors'
import { v4 as uuidv4 } from 'uuid'

const responseSchema = z.object({ success: z.boolean(), data: z.any() })

export const getProjectList = createAsyncThunk<
  CollectionsListDetailed['collections'],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/getProjectList', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get('/api/project/list', {
      params: { user_id: selectUserId(thunkApi.getState()) },
    })

    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    // Announcement: Sorted by last updated (DESC)
    return parserResponse.data.data.collections.sort(
      (a: Project, b: Project) => new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf(),
    )
  } catch (error: unknown) {
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const getTracksOfProject = createAsyncThunk<
  { tracks: DetailedTrack[] },
  Project['id'],
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/getTracksOfProject', async (projectId, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/project/tracks?id=${projectId}`)
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }
    // Announcement: reverse order means from last inserted
    return { ...parserResponse.data.data, tracks: parserResponse.data.data.tracks.reverse() }
  } catch (error: unknown) {
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

const SUGGESTIONS_PAGE_SIZE = 9

export const getSuggestionsForProject = createAsyncThunk<
  { tracks: DetailedTrack[] },
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  Project['id'],
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/getSuggestionsForProject', async (projectId, thunkApi) => {
  try {
    const response = await axiosInstance.get(
      `/api/project/suggestions?id=${projectId}&page=1&page_size=${SUGGESTIONS_PAGE_SIZE}`,
    )
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const refreshSuggestionsForProject = createAsyncThunk<
  { tracks: DetailedTrack[] },
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  { projectId: Project['id']; page?: number },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/refreshSuggestionsForProject', async ({ projectId, page }, thunkApi) => {
  try {
    const response = await axiosInstance.get(
      `/api/project/suggestions?id=${projectId}&page=${page ?? 1}&page_size=${SUGGESTIONS_PAGE_SIZE}`,
    )
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const createProject = createAsyncThunk<
  Project,
  { project: Pick<Project, 'title' | 'description'>; shouldAddTrack: boolean },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/createProject', async ({ project, shouldAddTrack }, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/project/create', {
      title: project.title,
      description: project.description,
      key: uuidv4(),
      user_id: selectUserId(thunkApi.getState()),
    })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.SUCCESS,
        message: `Project was created`,
      }),
    )

    const tempTrackToBeAdded = selectTempTrackToBeAdded(thunkApi.getState())
    if (tempTrackToBeAdded !== undefined && shouldAddTrack) {
      void thunkApi.dispatch(
        addTrackToProject({
          track: tempTrackToBeAdded,
          projectKey: parserResponse.data.data.collection.key,
          projectId: parserResponse.data.data.collection.id,
        }),
      )
    }

    return parserResponse.data.data.collection
  } catch (error: unknown) {
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const updateProject = createAsyncThunk<
  Project,
  Pick<Project, 'id' | 'title' | 'description'>,
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/updateProject', async ({ id, title, description }, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/project/update', { id, title, description })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.SUCCESS,
        message: `Project was updated`,
      }),
    )
    return parserResponse.data.data.collection
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const deleteProject = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  Project['id'],
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/deleteProject', async (projectId, thunkApi) => {
  try {
    await axiosInstance.delete(`/api/project/delete/${projectId}`)
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.SUCCESS,
        message: `Project was removed`,
      }),
    )
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const addTrackToProject = createAsyncThunk<
  | {
      track: DetailedTrack
      projectKey: string
      projectId: string // Announcement: to display loading state only
    }
  | undefined,
  { track: DetailedTrack; projectKey: string; projectId: string },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/addTrackToProject', async ({ track, projectKey, projectId }, thunkApi) => {
  try {
    if (selectIsTrackInProject(projectKey, track.id_client)(thunkApi.getState())) {
      void thunkApi.dispatch(
        addNotification({
          variant: NotificationStatus.INFO,
          message: 'This track is already in project',
        }),
      )
      return thunkApi.rejectWithValue({ code: 0, message: 'Track already in project' })
    }

    if (track === undefined) {
      console.error('project/addTrackToProject: Track not found')
      return
    }

    await axiosInstance.post('/api/project/add-track', { trackId: track.id_client, collectionKey: projectKey })

    return {
      track,
      projectKey,
      projectId,
    }
  } catch (error: unknown) {
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const addSuggestedTrack = createAsyncThunk<
  | {
      track: DetailedTrack
      project: Project
    }
  | undefined,
  { track: DetailedTrack; project: Project },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/addSuggestedTrack', async ({ track, project }, thunkApi) => {
  try {
    if (selectIsTrackInProject(project.key, track.id_client)(thunkApi.getState())) {
      void thunkApi.dispatch(
        addNotification({
          variant: NotificationStatus.INFO,
          message: 'This track is already in project',
        }),
      )
      return thunkApi.rejectWithValue({ code: 0, message: 'Track already in project' })
    }

    await axiosInstance.post('/api/project/add-track', { trackId: track.id_client, collectionKey: project.key })

    return {
      track,
      project,
    }
  } catch (error: unknown) {
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const removeTrack = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  { trackIdClient: string; projectId: Project['id'] },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/removeTrack', async ({ trackIdClient, projectId }, thunkApi) => {
  try {
    const trackInPlayer = selectTrackInPlayer(thunkApi.getState())
    if (trackInPlayer !== null && 'id_client' in trackInPlayer && trackInPlayer.id_client === trackIdClient) {
      thunkApi.dispatch(closePlayer())
    }

    const project = selectProjectById(projectId)(thunkApi.getState())
    if (project === undefined) {
      return
    }

    await axiosInstance.post('/api/project/remove-track', { trackId: trackIdClient, collectionKey: project.key })
  } catch (error: unknown) {
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const createSnapshot = createAsyncThunk<
  { id: CreateSnapshotResponse['id']; projectId: Project['id'] },
  Pick<CreateSnapshotRequest, 'downloadable' | 'collection_key'> & { projectId: Project['id'] },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/createSnapshot', async (data, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/project/snapshot/create', {
      downloadable: data.downloadable,
      collection_key: data.collection_key,
      user_id: selectUserId(thunkApi.getState()),
      streaming_secret: selectStreamingHash(thunkApi.getState()),
    })
    const parserResponse = responseSchema.safeParse(response.data)

    if (!parserResponse.success || !parserResponse.data.success || response.status !== 201) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }

    return { id: parserResponse.data.data.id, projectId: data.projectId }
  } catch (error) {
    const parsedError = parseSimilaritySeachApiError(error)
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: parsedError.message,
      }),
    )
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const getSnapshot = createAsyncThunk<
  Snapshot,
  string,
  {
    rejectValue: SimilaritySearchAPIError
  }
>('project/getSnapshot', async (snapshotId, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/project/snapshot/get?snapshotId=${snapshotId}`)
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const cloneSnapshot = createAsyncThunk<
  Project,
  { snapshotId: string; title: string },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('project/cloneSnapshot', async ({ snapshotId, title }, thunkApi) => {
  try {
    const key = uuidv4()
    const response = await axiosInstance.post('/api/project/snapshot/clone', {
      snapshot_id: snapshotId,
      user_id: selectUserId(thunkApi.getState()),
      key,
    })
    const parserResponse = responseSchema.safeParse(response.data)

    if (!parserResponse.success || !parserResponse.data.success || response.status > 201) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }

    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.SUCCESS,
        message: 'Project copied successfully',
      }),
    )

    return {
      ...parserResponse.data.data.collection,
      snapshotId,
    }
  } catch (error) {
    const parsedError = parseSimilaritySeachApiError(error)
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: parsedError.message,
      }),
    )
    return thunkApi.rejectWithValue(parsedError)
  }
})
