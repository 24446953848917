import { z } from 'zod'
import { errors } from '../consts/errors'
import { isAxiosError } from 'axios'

export interface CustomError {
  status: number | null
  message: string
  field?: string
}

const apiErrorSchema = z.object({
  response: z.object({
    status: z.number(),
    data: z.optional(
      z.object({
        field: z.optional(z.string()),
        message: z.optional(z.string()),
        status: z.optional(z.string()),
      }),
    ),
  }),
  message: z.optional(z.string()),
})

export interface ParsedErrorFromApi {
  status: number
  json: CustomError
}

export const parseErrorFromApi = (error: unknown): ParsedErrorFromApi => {
  const errorParserResponse = apiErrorSchema.safeParse(error)
  // Caution! "AxiosError: Request failed with status code 400" error has two possible locations of a message output.
  if (!errorParserResponse.success) {
    if (error instanceof Error) {
      return {
        status: errors.PARSER.status,
        json: { status: errors.PARSER.status, message: error.message },
      }
    }
    return {
      status: errors.PARSER.status,
      json: errors.PARSER,
    }
  }
  const errorData = errorParserResponse.data
  return {
    status: errorData.response.status,
    json: {
      status: errorData.response.status,
      message: errorData.response.data?.message ?? errorData.response?.data?.status ?? errorData.message ?? '',
      field: errorData.response?.data?.field,
    },
  }
}

export const thunkErrorSchema = z.object({
  status: z.number(),
  message: z.string(),
  field: z.optional(z.string()),
})

const similarityApiErrorSchema = z.object({
  code: z.optional(z.number()),
  message: z.optional(z.string()),
  payload: z.optional(z.record(z.string(), z.any()).or(z.object({ code: z.number(), message: z.string() }))),
})

export type SimilaritySearchAPIError = z.infer<typeof similarityApiErrorSchema>

export const parseSimilaritySeachApiError = (error: unknown) => {
  const similarityApiErrorParser = similarityApiErrorSchema.safeParse(error)
  if (similarityApiErrorParser.success) {
    return {
      code: similarityApiErrorParser.data.code ?? similarityApiErrorParser.data.payload?.code ?? 0,
      message: similarityApiErrorParser.data.message ?? similarityApiErrorParser.data.payload?.message ?? '',
    }
  }
  return { code: 0, message: 'Not able to parse error' }
}

export const parseErrorFromThunk = (error: unknown) => {
  const notParsed: CustomError = {
    status: 500,
    message: 'Not able to parse error',
  }

  if (isAxiosError(error) && error.response !== undefined) {
    const errorParserResponse = thunkErrorSchema.safeParse(error.response.data)
    if (!errorParserResponse.success) {
      return notParsed
    }
    return errorParserResponse.data
  }
  return notParsed
}
