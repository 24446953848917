import { createSelector } from '@reduxjs/toolkit'
import { AIMS_STANDARD_PLAN_ID } from '../../consts/common'
import { AIMS_STANDARD_PRESET } from '../../consts/taxonomyMapping'
import { RootState } from '../store'

export const selectIsDarkMode = (state: RootState) => state.apiUser.darkMode

export const selectAvailablePresets = (availablePresetIds: string[]) =>
  createSelector(
    [(state: RootState) => state.apiUser.userPresets, (state: RootState) => state.auth.userData.stripeProductId],
    (presets, stripeProductId) => {
      return presets.flatMap((preset) => {
        if (!availablePresetIds.includes(preset.id) && preset.id !== AIMS_STANDARD_PRESET.id) {
          return []
        }

        return {
          id: preset.id,
          name: preset.name,
          locked: preset.premium && !AIMS_STANDARD_PLAN_ID.includes(stripeProductId ?? ''),
        }
      })
    },
  )

export const selectCurrentPresetId = (state: RootState) => state.apiUser.currentPresetId
export const selectCurrentPreset = () => (state: RootState) =>
  state.apiUser.userPresets.find((preset) => preset.id === state.apiUser.currentPresetId)
